import React, { Fragment, useEffect, useState } from 'react';
import {
	Avatar,
	Badge,
	Card,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Icon,
	IconButton,
	Typography
} from '@mui/material';
import CardDetail from './CardDetail';
import * as HotelsDetail from './utils/HotelsDetail';
import * as TrainsDetail from './utils/TrainsDetail';
import { useTranslation } from 'react-i18next';

const DetailDialog = ({
  purchaseDetail,
  icon,
  products,
  module
}) => {
  const { t } = useTranslation();
	const [open, setOpen] = useState(false);
  const [selectedDataTotal, setSelectedDataTotal] = useState([]);

  useEffect(() => {
    const selectedDataTotalAux = [];
    if (products) {
      if (Array.isArray(products)) {
        products.forEach(product => {
          if (product.pivot && product.pivot.length > 0) {
            selectedDataTotalAux.push(product.pivot[0]);
          }
        });
      } else {
        selectedDataTotalAux.push(products.pivot[0]);
      }
    }
    setSelectedDataTotal(selectedDataTotalAux);
  }, [products]);

	const handleClickOpen = () => setOpen(true);

	const handleClose = () => setOpen(false);

  const getProductsDetails = (products) => {
    if (module === 'hotels') {
      return HotelsDetail.getProductsDetails(products);
    }
  }

  const getPriceDetails = () => {
    let priceDetails = null;
    let showDivider = true;

    if (module === 'trains') {
      priceDetails = TrainsDetail.getPriceDetails(purchaseDetail, true);
      showDivider = false;
    }

    if (priceDetails) {
      return <Grid className='prices'>
        {showDivider && <Divider />}
        <Grid container className='row'>
          {priceDetails.map((elem, i) => (
            <Fragment key={i}>
              {elem.value > 0 && (
                <>
                  <Grid item xs={7} className='left'>
                    <Typography className={elem.classname}>{t(`checkout.common.${elem.title}`)}</Typography>
                    {elem.showTax && Boolean(purchaseDetail?.taxAmount) && purchaseDetail?.taxAmount > 0 && (
                      <Typography className='taxes'>
                        {t('checkout.common.taxesIncluded')} {purchaseDetail?.currency} {purchaseDetail?.taxAmount}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={5} className='right'>
                    <Typography className={`text-right ${elem.classname}`}>
                      {purchaseDetail?.currency} {elem.value}
                    </Typography>
                  </Grid>
                </>
              )}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    }
  }

	return (
		<Fragment>
			<IconButton onClick={handleClickOpen}>
				<Icon>{icon}</Icon>
			</IconButton>

			<Dialog
				onClose={handleClose}
				open={open}
				className={`modal-detail-purchase ${module}-details`}
        maxWidth='md'
        fullWidth
			>
				<DialogTitle>
					<Card className='purchase-card-modal card-primary-color'>
						<CardHeader
							avatar={
                <Avatar className='avatar-purchase-detail'>
                  <Badge
                    badgeContent={1}
                    color='primary'
                    overlap='circular'
                  >
                    <Icon>{purchaseDetail?.icon}</Icon>
                  </Badge>
                </Avatar>
              }
              title={
                <Typography className='title-card-header'>
                  {purchaseDetail?.title}
                </Typography>
              }
              subheader={
                <Typography noWrap className='title-card-subheader' >
                  {purchaseDetail?.subtitle}
                </Typography>
              }
              action={
								<IconButton className='card-header-action' onClick={handleClose}>
									<Icon>close</Icon>
								</IconButton>
              }
						/>
					</Card>
				</DialogTitle>
        <DialogContent dividers className='dialog-content-resume'>
          <Grid className='products'>
            {getProductsDetails(purchaseDetail?.priceDetail)}
          </Grid>

          {getPriceDetails()}

          <Grid className='dialog-footer'>
            {selectedDataTotal.map((data, i) => <CardDetail key={i} cardDetail={data.cardDetail} module={module} />)}
          </Grid>
        </DialogContent>
			</Dialog>
		</Fragment>
	);
}

export default DetailDialog;
