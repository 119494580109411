import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Skeleton,
  Slider,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { Check, Tune, RemoveCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const TabPanel = (props) => {
  const { children, value, index, className = '' } = props;

  return (
    <div hidden={value !== index} className={`tab-panel ${className}`}>
      {value === index && children}
    </div>
  );
}

const FiltersComponent = ({
  filters,
  loading,
  currency,
  dataForm,
  handleChangeFilters
}) => {
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useState({});
  const [rangePrice, setRangePrice] = useState([0, 0]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [rangeSchedule, setRangeSchedule] = useState([]);

  useEffect(() => {
    if (filters) {
      setRangePrice([
        parseInt(filters['priceCount']?.min || 0),
        parseInt(filters['priceCount']?.max || 0)
      ]);
      setSelectedTab(0);
      setRangeSchedule(filters?.scheduleDepartureCount?.resultados?.map(section => ({
        departure: [dayjs(section?.options?.[0]?.min).unix(), dayjs(section?.options?.[0]?.max).unix()],
        arrival: [dayjs(section?.options?.[1]?.min).unix(), dayjs(section?.options?.[1]?.max).unix()]
      })) || []);

      const filtersAux = {
        stopover: filters['stopoversCount']?.resultados?.filter(elem => elem.active) || [],
        fareType: filters['fareTypeCount']?.resultados?.filter(elem => elem.active) || [],
        contentType: filters['contentTypeCount']?.resultados?.filter(elem => elem.active) || [],
        baggage: filters['baggageCount']?.resultados?.filter(elem => elem.active) || [],
        airline: filters['airlineCount']?.resultados?.filter(elem => elem.active) || [],
        airport: filters['airportCount']?.resultados?.filter(elem => elem.active) || []
      };
      setActiveFilters(filtersAux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleClearAllFilters = () => {
    const activeFiltersAux = {};
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleClearFilter = (filter) => {
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux[filter] = [];
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleChangeFilter = (filter, value, checked) => {
    const activeFiltersAux = {...activeFilters};
    if (checked) {
      if (!activeFiltersAux[filter]) {
        activeFiltersAux[filter] = [value];
      } else {
        activeFiltersAux[filter].push(value);
      }
    } else {
      activeFiltersAux[filter] = activeFiltersAux[filter].filter(elem => elem.value !== value.value);
    }

    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleChangeRangePrice = (event, newValue, activeThumb) => {
    const minDistance = 0;

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRangePrice([Math.min(newValue[0], rangePrice[1] - minDistance), rangePrice[1]]);
    } else {
      setRangePrice([rangePrice[0], Math.max(newValue[1], rangePrice[0] + minDistance)]);
    }
  }

  const handleClearRangePrice = () => {
    setRangePrice([
      filters['priceCount']?.min || 0,
      filters['priceCount']?.max || 0
    ]);
    handleChangeFilters && handleChangeFilters(activeFilters);
  }

  const handleApplyPrice = () => {
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux.price = rangePrice;
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleChangeSelectedTab = (index) => {
    setSelectedTab(index);
  }

  const handleChangeRangeSchedule = (event, newValue, activeThumb, index, field) => {
    const minDistance = 0;

    if (!Array.isArray(newValue)) {
      return;
    }

    let rangeScheduleAux = [...rangeSchedule];

    if (activeThumb === 0) {
      rangeScheduleAux[index][field] = [Math.min(newValue[0], rangeScheduleAux[index][field][1] - minDistance), rangeScheduleAux[index][field][1]];
    } else {
      rangeScheduleAux[index][field] = [rangeScheduleAux[index][field][0], Math.max(newValue[1], rangeScheduleAux[index][field][0] + minDistance)];
    }

    setRangeSchedule(rangeScheduleAux);
  }

  const formatValueSchedule = (value) => {
    return dayjs.unix(value).format('ddd HH:mm');
  }

  const handleClearRangeSchedule = () => {
    setRangeSchedule(filters?.scheduleDepartureCount?.resultados?.map(section => ({
      departure: [dayjs(section?.options?.[0]?.min).unix(), dayjs(section?.options?.[0]?.max).unix()],
      arrival: [dayjs(section?.options?.[1]?.min).unix(), dayjs(section?.options?.[1]?.max).unix()]
    })) || []);
    handleChangeFilters && handleChangeFilters(activeFilters);
  }

  const handleApplySchedule = () => {
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux.schedule = rangeSchedule;
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  return (
    <Grid className='filters-component'>
      <Grid className='row title' justifyContent='space-between'>
        <Grid className='row'><Tune />{t('filters.flights.filterSearch')}</Grid>
        <Grid className='row'>
          <Button className='clear-filter-button' onClick={handleClearAllFilters} disabled={loading}>
            <RemoveCircleOutline />{t('filters.flights.clear')}
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        Array.from(Array(5).keys()).map(elem => (
          <Fragment key={elem}>
            <Grid className='section skeleton'>
              <Grid className='row' justifyContent='space-between'>
                <Skeleton variant='rounded' width='50%' />
              </Grid>
              <Grid className='filters'>
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
              </Grid>
            </Grid>

            {elem < 4 && <Divider />}
          </Fragment>
        ))
      ) : (
        <>
          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.filterByStopovers')}</Typography>
              {filters['stopoversCount']?.resultados?.some(elem => elem.active) && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('stopover')}>
                    <RemoveCircleOutline />{t('filters.flights.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters['stopoversCount']?.resultados?.map(elem => (
                <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={elem.active}
                        onChange={(e, checked) => handleChangeFilter('stopover', elem, checked)}
                        disabled={!elem.total}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.filterByBaggage')}</Typography>
              {filters['baggageCount']?.resultados?.some(elem => elem.active) && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('baggage')}>
                    <RemoveCircleOutline />{t('filters.flights.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters['baggageCount']?.resultados?.map(elem => (
                <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={elem.active}
                        onChange={(e, checked) => handleChangeFilter('baggage', elem, checked)}
                        disabled={!elem.total}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.filterByAirline')}</Typography>
              {filters['airlineCount']?.resultados?.some(elem => elem.active) && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('airline')}>
                    <RemoveCircleOutline />{t('filters.flights.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters['airlineCount']?.resultados?.map(elem => (
                <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={elem.active}
                        onChange={(e, checked) => handleChangeFilter('airline', elem, checked)}
                        disabled={!elem.total}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.filterByFareType')}</Typography>
              {filters['fareTypeCount']?.resultados?.some(elem => elem.active) && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('fareType')}>
                    <RemoveCircleOutline />{t('filters.flights.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters['fareTypeCount']?.resultados?.map(elem => (
                <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={elem.active}
                        onChange={(e, checked) => handleChangeFilter('fareType', elem, checked)}
                        disabled={!elem.total}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.priceRange')}</Typography>
              <Grid className='row'>
                <Button onClick={handleClearRangePrice}>
                  <RemoveCircleOutline />{t('filters.flights.clear')}
                </Button>
              </Grid>
            </Grid>
            <Grid className='filters range-price'>
              <Grid className='row' justifyContent='space-between'>
                <span>{currency} {rangePrice[0]}</span>
                <span>{currency} {rangePrice[1]}</span>
              </Grid>
              <Slider
                min={parseInt(filters['priceCount']?.min || 0)}
                max={parseInt(filters['priceCount']?.max || 0)}
                disableSwap
                value={rangePrice}
                onChange={handleChangeRangePrice}
              />
            </Grid>
            <Grid display='flex' justifyContent='flex-end'>
              <Button variant='contained' size='small' style={{ marginTop: 10 }} onClick={handleApplyPrice}>
                <Check fontSize='small' />{t('filters.flights.apply')}
              </Button>
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.filterByContentType')}</Typography>
              {filters['contentTypeCount']?.resultados?.some(elem => elem.active) && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('contentType')}>
                    <RemoveCircleOutline />{t('filters.flights.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters['contentTypeCount']?.resultados?.map(elem => (
                <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={elem.active}
                        onChange={(e, checked) => handleChangeFilter('contentType', elem, checked)}
                        disabled={!elem.total}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.filterByAirport')}</Typography>
              {filters['airportCount']?.resultados?.some(elem => elem?.options?.some(elem => elem.active)) && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('airport')}>
                    <RemoveCircleOutline />{t('filters.flights.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters['airportCount']?.resultados?.map(elem => (
                <Grid className='airports-container'>
                  <Typography><b>{elem.value}</b></Typography>
                  {elem?.options?.map(airport => (
                    <Grid className='filter' justifyContent='space-between' key={airport.idFilter}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={airport.active}
                            onChange={(e, checked) => handleChangeFilter('airport', airport, checked)}
                            disabled={!airport.total}
                          />
                        }
                        label={airport.value}
                      />
                      <span>({airport.total})</span>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.flights.filterBySchedule')}</Typography>
              <Grid className='row'>
                <Button className='clear-filter-button' onClick={handleClearRangeSchedule}>
                  <RemoveCircleOutline />{t('filters.flights.clear')}
                </Button>
              </Grid>
            </Grid>
            <Grid className='filters schedule'>
                <Tabs
                  variant='fullWidth'
                  scrollButtons='auto'
                  value={selectedTab}
                  onChange={(e, value) => handleChangeSelectedTab(value)}
                >
                  {filters['scheduleDepartureCount']?.resultados?.map((elem, i) => (
                    <Tab key={i} value={i} label={`${t('filters.flights.section')} ${i + 1}`} />
                  ))}
                </Tabs>
                {filters['scheduleDepartureCount']?.resultados?.map((elem, i) => (
                  <TabPanel value={selectedTab} index={i} key={i}>
                    <Typography><b>{t('filters.flights.departureFrom', { from: dataForm?.segments?.[i]?.origin?.iata })}</b></Typography>
                    <Typography>{dayjs(elem?.options?.[0]?.min).format('ddd HH:mm')} - {dayjs(elem?.options?.[0]?.max).format('ddd HH:mm')}</Typography>
                    <Slider
                      min={dayjs(elem?.options?.[0]?.min).unix()}
                      max={dayjs(elem?.options?.[0]?.max).unix()}
                      disableSwap
                      valueLabelDisplay='auto'
                      step={60}
                      valueLabelFormat={formatValueSchedule}
                      value={rangeSchedule?.[i]?.departure || [0, 0]}
                      onChange={(event, value, activeThumb) => handleChangeRangeSchedule(event, value, activeThumb, i, 'departure')}
                    />
                    <Typography><b>{t('filters.flights.arrivalTo', { to: dataForm?.segments?.[i]?.destination?.iata })}</b></Typography>
                    <Typography>{dayjs(elem?.options?.[1]?.min).format('ddd HH:mm')} - {dayjs(elem?.options?.[1]?.max).format('ddd HH:mm')}</Typography>
                    <Slider
                      min={dayjs(elem?.options?.[1]?.min).unix()}
                      max={dayjs(elem?.options?.[1]?.max).unix()}
                      disableSwap
                      valueLabelDisplay='auto'
                      step={60}
                      valueLabelFormat={formatValueSchedule}
                      value={rangeSchedule?.[i]?.arrival || [0, 0]}
                      onChange={(event, value, activeThumb) => handleChangeRangeSchedule(event, value, activeThumb, i, 'arrival')}
                    />
                  </TabPanel>
                ))}
            </Grid>
            <Grid display='flex' justifyContent='flex-end'>
              <Button variant='contained' size='small' style={{ marginTop: 10 }} onClick={handleApplySchedule}>
                <Check fontSize='small' />{t('filters.flights.apply')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FiltersComponent;
