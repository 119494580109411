import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getCredentials } from './util';

function* searchActionFlights(actions) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': actions.token
    }
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const url = `${getCredentials().urlFlights}search`;
    const response = yield call(axios.post, url, actions.data, requestOptions);
    yield put({ type: 'GET_PRODUCT_SEARCH_SUCCESS_FLIGHTS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_SEARCH_ERROR_FLIGHTS', payload: error.message });
  }
}

function* searchThreeDaysActionFlights(actions) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': actions.token
    }
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const url = `${getCredentials().urlFlights}searchComparison`;
    const response = yield call(axios.post, url, actions.data, requestOptions);
    yield put({ type: 'GET_PRODUCT_SEARCH_THREE_DAYS_SUCCESS_FLIGHTS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_SEARCH_THREE_DAYS_ERROR_FLIGHTS', payload: error.message });
  }
}

function* availabilityActionFlights(actions) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token': actions.token
    }
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.post, `${getCredentials().urlFlights}availability`, actions.data, requestOptions);
    if (response?.data?.error) {
      yield put({ type: 'GET_PRODUCT_AVAILABILITY_ERROR_FLIGHTS', payload: response.data });
    } else {
      yield put({ type: 'GET_PRODUCT_AVAILABILITY_SUCCESS_FLIGHTS', payload: response.data });
    }
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_AVAILABILITY_ERROR_FLIGHTS', payload: error.message });
  }
}

function* getFiltersActionFlights(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.get, `${getCredentials().url}flights/info${actions.query}`, requestOptions);
    yield put({ type: 'GET_PRODUCT_FILTERS_SUCCESS_FLIGHTS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_FILTERS_ERROR_FLIGHTS', payload: error.message });
  }
}

function* filterActionFlights(actions) {
  const headers = {
    'Content-Type': "application/json"
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.get, `${getCredentials().url}flights/filter${actions.query}`, requestOptions);
    yield put({ type: 'GET_PRODUCT_FILTER_SUCCESS_FLIGHTS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_PRODUCT_FILTER_ERROR_FLIGHTS', payload: error?.response?.data?.error || error?.message });
  }
}

export function* searchSagaFlights() {
  yield takeEvery('GET_PRODUCT_SEARCH_FLIGHTS', searchActionFlights);
  yield takeEvery('GET_PRODUCT_SEARCH_THREE_DAYS_FLIGHTS', searchThreeDaysActionFlights);
  yield takeEvery('GET_PRODUCT_AVAILABILITY_FLIGHTS', availabilityActionFlights);
  yield takeEvery('GET_PRODUCT_FILTERS_FLIGHTS', getFiltersActionFlights);
  yield takeEvery('GET_PRODUCT_FILTER_FLIGHTS', filterActionFlights);
}
