import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    InputAdornment,
    FormLabel,
    Select,
    MenuItem,
    Typography,
    Autocomplete,
    Grid,
    IconButton,
    FormHelperText,
    Divider,
    Popover,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Hidden,
    FormControlLabel,
    Switch
} from '@mui/material';
import dayjs from 'dayjs';
import { DesktopDatePicker, PickersDay } from '@mui/x-date-pickers';
import { getAutocompleteFlights } from '../../../store/services/Autocomplete';
import {
    AddBox,
    Add,
    ArrowBackOutlined,
    ArrowDropDown,
    ArrowDropUp,
    Close,
    Delete,
    LocationOn,
    PeopleAlt,
    Person,
    Remove,
    Search,
    SwapHoriz,
    InsertInvitation,
    DateRange
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';

const DATE_FORMAT = 'DD/MM/YYYY';

const MultibuscadorFlights = ({ defaultData, isResultsView, allDestinations, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { setClientData } = useClientData();
    const [formData, setFormData] = useState({
        segments: [{
            origin: '',
            destination: '',
            dateDeparture: dayjs().format(DATE_FORMAT),
            dateArrive: ''
        }],
        adults: 1,
        kids: 0,
        babys: 0,
        currency: 'USD',
        tripType: 'roundtrip',
        cabinClasses: 'Y',
        threeDays: false
    });
    const [resumeData, setResumeData] = useState();
    const [anchorEl, setAnchorEl] = useState();
    const [openCheckin, setOpenCheckin] = useState(false);
    const [openCheckout, setOpenCheckout] = useState(false);
    const [timeoutAutocomplete, setTimeoutAutocomplete] = useState();
    const [abortController, setAbortController] = useState();
    const [loadingDestinations, setLoadingDestinations] = useState();
    const [destinations, setDestinations] = useState([]);
    const [errors, setErrors] = useState({ segments: [], minorAges: [] });
    const [expanded, setExpanded] = useState(true);
    const [dayHover, setDayHover] = useState();
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

    useEffect(() => {
        if (isResultsView) {
            setExpanded(false);
        }

        ;(async () => {
            if (!defaultData) {
                const response = await getAutocompleteFlights('buenos aires');
                if (response?.data && Array.isArray(response.data)) {
                    const defaultOrigin = response.data
                    .find(elem => elem.category === 'Ciudades')
                    ?.data
                    ?.find(elem => elem.iata === 'BUE');
                    setFormData(current => ({
                        ...current,
                        segments: [{
                            ...current.segments[0],
                            origin: defaultOrigin || ''
                        }]
                    }))
                }
            }
          })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (defaultData) {
            setFormData(defaultData);

            const resumeDataAux = JSON.parse(JSON.stringify(defaultData));
            resumeDataAux.segments.forEach(elem => {
                elem.origin = allDestinations.filter(destination => destination.iata === elem.origin?.iata).pop();
                elem.destination = allDestinations.filter(destination => destination.iata === elem.destination?.iata).pop();
            })
            setResumeData(resumeDataAux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultData, allDestinations]);

    const handleSubmit = (e) => {
        e?.preventDefault();

        const user = localStorage.getItem('user');
        if (user) {
            const isPasses = formData.tripType === 'passes';
            const errorsAux = {};
            let errorsCount = 0;

            errorsAux.segments = formData.segments.map(elem => {
                const errors = {
                    origin: !isPasses ? !Boolean(elem.origin) : false,
                    destination: !Boolean(elem.destination),
                    dateDeparture: !Boolean(elem.dateDeparture),
                    dateArrive: !isPasses ? formData.tripType.includes('roundtrip') && !Boolean(elem.dateArrive) : false
                };
                errorsCount += Object.values(errors).filter(elem => elem === true).length;
                return errors;
            });

            const totalPassengers = formData.adults + formData.kids + formData.babys;
            if (totalPassengers === 0) {
                errorsAux.noPassengers = true;
                errorsCount += 1;
            }

            setErrors(errorsAux);

            if (errorsCount === 0) {
                setExpanded(false);

                const origin = [];
                const destination = [];
                const dateDeparture = [];
                const dateArrive = formData.tripType === 'roundtrip' ? [] : null;

                formData.segments.forEach(elem => {
                    origin.push(elem.origin.iata);
                    destination.push(elem.destination.iata);
                    dateDeparture.push(dayjs(elem.dateDeparture, DATE_FORMAT).format('DDMMYY'));
                    if (elem.dateArrive) {
                        dateArrive.push(dayjs(elem.dateArrive, DATE_FORMAT).format('DDMMYY'));
                    }
                });

                const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
                const url = iframePath + `/vuelos/resultados/${formData.tripType}/${origin.join(',')}/${destination.join(',')}/${dateDeparture.join(',')}/${dateArrive?.join(',') || 'NA'}/${formData.adults}/${formData.kids}/${formData.babys}/NA/NA/NA/${formData.cabinClasses}/NA/${formData.threeDays ? 1 : 0}`;
                navigate(url);
            }
        } else {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            if (iframePath) {
                localStorage.clear();
                setClientData(null);
                navigate(iframePath);
            } else {
                navigate('/login', { state: { expiredSession: true } });
            }
        }
    };

    const handleChangeDestination = (value, ) => {
        if (value && value.length > 2) {
            clearTimeout(timeoutAutocomplete)
            const timeout = setTimeout(async () => {
                abortController && abortController.abort();
                const controller = new AbortController();
                setAbortController(controller);
                setLoadingDestinations(true);
                const response = await getAutocompleteFlights(value, controller.signal);
                response?.data?.forEach(data => {
                    data.data = data.data.map(elem => ({
                        ...elem,
                        category: data.category.toLowerCase() === 'ciudades' ? 'cities' : 'airports',
                    }))
                })
                setDestinations(response.data?.flatMap(group => group.data) || [])
                setAbortController(null);
                setLoadingDestinations(false);
                setAbortController(null);
                setLoadingDestinations(false);
            }, 500);
            setTimeoutAutocomplete(timeout);
        }
    }

    const handleBlurField = () => {
        abortController && abortController.abort();
        clearTimeout(timeoutAutocomplete);
        setDestinations([]);
        setAbortController(null);
        setLoadingDestinations(false);
    }

    const handleClickSwapDestinations = (index) => {
        const segments = [...formData.segments];
        const originAux = segments[index].origin;
        segments[index].origin = segments[index].destination;
        segments[index].destination = originAux;

        setFormData(current => ({
            ...current,
            segments
        }));
    }

    const handleChangeSegmentField = (field, index, value) => {
        const segments = [...formData.segments];
        segments[index][field] = value;

        if (field === 'dateDeparture') {
            const dateDeparture = value ? dayjs(value, DATE_FORMAT) : null;
            const dateArrive = segments[index].dateArrive ? dayjs(segments[index].dateArrive, DATE_FORMAT) : null;

            if (dateDeparture) {
                if (dateArrive && dateDeparture.isAfter(dateArrive, 'date')) {
                    segments[index].dateArrive = '';
                }

                segments.forEach((elem, i) => {
                    if (i > index) {
                        const dateDepartureAux = elem.dateDeparture ? dayjs(elem.dateDeparture, DATE_FORMAT) : null
                        if (dateDepartureAux && dateDeparture.isAfter(dateDepartureAux, 'date')) {
                            segments[i].dateDeparture = dayjs(dateDeparture).add(i - index, 'day').format(DATE_FORMAT);
                        }
                    }
                })
            }

            if (formData.tripType.includes('roundtrip')) {
                setOpenCheckout(index);
            }
        }

        setFormData(current => ({
            ...current,
            segments
        }));
    }

    const handleChangeField = (field, value) => {
        let formDataAux = {
            ...formData,
            [field]: value
        };

        if (field === 'tripType') {
            formDataAux.threeDays = false;

            if (value === 'multidestination') {
                formDataAux.segments = [
                    {
                        ...formDataAux.segments[0],
                        dateArrive: ''
                    },
                    {
                        origin: '',
                        destination: '',
                        dateDeparture: '',
                        dateArrive: ''
                    }
                ];
            } else {
                formDataAux.segments = [{
                    ...formDataAux.segments[0],
                    dateArrive: ''
                }];
            }
        }

        setFormData(formDataAux);
    }

    const handleAddSegment = () => {
        let dateDeparture = formData.segments[formData.segments.length - 1].dateDeparture;
        dateDeparture = dayjs(dateDeparture, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT);
        const segments = [...formData.segments];
        segments.forEach(elem => elem.dateArrive = '');
        segments.push({
            origin: '',
            destination: '',
            dateDeparture
        });
        setFormData(current => ({
            ...current,
            segments,
            tripType: 'multidestination'
        }))
    }

    const handleRemoveSegment = (index) => {
        const segments = [...formData.segments];
        segments.splice(index, 1);
        if (segments.length === 1) {
            segments[0].dateArrive = '';
        }

        setFormData(current => ({
            ...current,
            segments,
            tripType: segments.length === 1 ? 'roundtrip' : current.tripType
        }))
    }

    const getMaxPassengers = (field) => {
        const fields = ['adults', 'kids'];
        let count = 9;

        fields.forEach(elem => {
            if (elem !== field) {
                count -= (formData[elem] || 0);
            }
        });

        return count;
    }

    const handleClickGuests = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseGuests = () => {
        setAnchorEl(null);
    };

    const getResumeData = () => {
        const passengers = resumeData?.adults + resumeData?.kids + resumeData?.babys;

        let destinations = resumeData?.segments?.map(segment => ([
            segment.origin?.label,
            segment.destination?.label
        ])) || [];
        destinations = destinations.filter(elem => elem && elem.every(destination => destination));

        if (resumeData) {
            return <>
                {destinations.map((destination, i) => (
                    <Grid className='row destination' key={i}>
                        <LocationOn />
                        {destination.join(' - ')}
                    </Grid>
                ))}
                {resumeData?.segments?.map((segment, i) => (
                    <Grid className='row date' key={i}>
                        <InsertInvitation />
                        {dayjs(segment.dateDeparture, DATE_FORMAT).format('dddd, DD MMM YY')}
                    </Grid>
                ))}
                {passengers && (
                    <Grid className='row pax'>
                        <PeopleAlt />
                        {passengers}
                    </Grid>
                )}
            </>
        }
    }

    const getSubmitButton = () => {
        return <Button
            variant="contained"
            color="secondary"
            size="small"
            type='submit'
            className='submit-button'
            sx={{ width: { xl: '100%' } }}
        >
            {t('common.search')}
        </Button>
    }

    const isSelected = (day, segment) => {
        const startDate = segment.dateDeparture && dayjs(segment.dateDeparture, DATE_FORMAT);
        const endDate = segment.dateArrive && dayjs(segment.dateArrive, DATE_FORMAT);

        return (startDate ? startDate.isSame(day, 'date') : false)
            || (endDate ? endDate.isSame(day, 'date') : false);
    }

    const isBetween = (day, segment) => {
        if (segment.dateDeparture && segment.dateArrive) {
            return day.isBetween(dayjs(segment.dateDeparture, DATE_FORMAT), dayjs(segment.dateArrive, DATE_FORMAT), 'date', '[]');
        }

        return false
    }

    const isDisabled = (day, segment, index) => {
        let flag = false;

        if (day.isBefore(dayjs(), 'date')) {
            flag = flag || true;
        }

        if (openCheckin !== null) {
            let previousDate = formData?.segments?.[index - 1]?.dateArrive || formData?.segments?.[index - 1]?.dateDeparture;
            if (previousDate) {
                previousDate = dayjs(previousDate, DATE_FORMAT).add(1, 'day');
                flag = flag || day.isBefore(previousDate, 'date');
            }
        }

        if (openCheckout !== null && segment.dateDeparture) {
            const startDate = dayjs(segment.dateDeparture, DATE_FORMAT).add(1, 'day');
            flag = flag || day.isBefore(startDate, 'date');
        }

        return flag;
    }

    const getPickerDay = (day, _value, DayComponentProps, segment, index) => {
        const date = dayjs(day.$d);
        const isBetweenAux = isBetween(date, segment);
        const isStartDate = segment.dateDeparture === date.format(DATE_FORMAT);
        const isEndDate = segment.dateArrive === date.format(DATE_FORMAT);

        const classNames = [];
        if (isBetweenAux) classNames.push('range-between');
        if (isStartDate) classNames.push('start-date');
        if (isEndDate) classNames.push('end-date');

        const classNamesDay = [];
        if (!isStartDate && !isEndDate) {
            if (isBetweenAux) {
                classNamesDay.push('between');
            } else if (segment.dateDeparture && dayHover) {
                if (openCheckin !== null && segment.dateArrive && date.isBetween(dayHover, dayjs(segment.dateDeparture, DATE_FORMAT), 'date', '()')) {
                    classNamesDay.push('preselected-day');
                } else if (openCheckout !== null) {
                    const dateAux = segment.dateArrive ? dayjs(segment.dateArrive, DATE_FORMAT) : dayjs(segment.dateDeparture, DATE_FORMAT);
                    segment.dateArrive && date.isBetween(dayjs(segment.dateArrive, DATE_FORMAT), dayHover, 'date', '()')
                    if (dateAux && date.isBetween(dateAux, dayHover, 'date', '()')) {
                        classNamesDay.push('preselected-day');
                    }
                }
            }
        }

        return (
            <div className={classNames.join(' ')} key={DayComponentProps.key}>
                <PickersDay
                    {...DayComponentProps}
                    className={classNamesDay.join(' ')}
                    selected={isSelected(date, segment)}
                    disabled={isDisabled(date, segment, index)}
                    onMouseOver={() => setDayHover(date)}
                    onMouseLeave={() => setDayHover(null)}
                    disableHighlightToday
                />
            </div>
        );
    }

    const getPassengersValue = () => {
        const passengers = [
            `${formData.adults} ${formData.adults === 1 ? t('multiSearch.flights.adult') : t('multiSearch.flights.adults')}`,
            `${formData.kids} ${formData.kids === 1 ? t('multiSearch.flights.kid') : t('multiSearch.flights.kids')}`,
            `${formData.babys} ${formData.babys === 1 ? t('multiSearch.flights.baby') : t('multiSearch.flights.babys')}`
        ];

        return passengers.join(', ');
    }

    return (
        <Box className="flights-search search-box-container">
            {isResultsView && (
                <Grid className='resume-container row'>
                    <Button className='back-button' onClick={() => navigate('/trenes')}>
                        <ArrowBackOutlined fontSize='small' />
                        {t('common.back')}
                    </Button>

                    {getResumeData()}

                    <Button className='modify-button' variant='outlined' onClick={() => setExpanded(!expanded)}>
                        {expanded ? <Close fontSize='small' /> : <Search fontSize='small' />}
                        {expanded ? t('common.cancel') : t('common.modifyAndAdd')}
                    </Button>
                </Grid>
            )}

            <Accordion expanded={expanded}>
                <AccordionSummary />
                <AccordionDetails>
                    <form onSubmit={e => handleSubmit(e)}>
                        <Grid container className="search-box" spacing={1}>
                            <Grid item xs={12} md={8} className='row mb-05 buttons-container'>
                                <Grid className='trip-type-buttons'>
                                    <Button
                                        variant='outlined'
                                        className={formData.tripType === 'roundtrip' ? 'selected' : ''}
                                        onClick={e => handleChangeField('tripType', 'roundtrip')}
                                    >
                                        {t('multiSearch.flights.roundtrip')}
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        className={formData.tripType === 'oneway' ? 'selected' : ''}
                                        onClick={e => handleChangeField('tripType', 'oneway')}
                                    >
                                        {t('multiSearch.flights.oneWay')}
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        className={formData.tripType === 'multidestination' ? 'selected' : ''}
                                        onClick={e => handleChangeField('tripType', 'multidestination')}
                                    >
                                        {t('multiSearch.flights.multidestination')}
                                    </Button>
                                </Grid>
                                <Hidden smDown>
                                   <Divider orientation="vertical" flexItem />
                                </Hidden>
                                <Grid className='class-input row'>
                                    <Typography>{t('multiSearch.flights.class')}</Typography>
                                    <Select
                                        variant='outlined'
                                        size='small'
                                        value={formData.cabinClasses}
                                        onChange={e => handleChangeField('cabinClasses', e.target.value)}
                                    >
                                        <MenuItem value='NA'>{t('multiSearch.flights.all')}</MenuItem>
                                        <MenuItem value='Y'>{t('multiSearch.flights.economy')}</MenuItem>
                                        <MenuItem value='W'>{t('multiSearch.flights.premium')}</MenuItem>
                                        <MenuItem value='C'>{t('multiSearch.flights.business')}</MenuItem>
                                        <MenuItem value='F'>{t('multiSearch.flights.firstClass')}</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            {formData.tripType === 'roundtrip' && (
                                <Grid item xs={12} md={4} className='three-days-container'>
                                    <FormControlLabel
                                        label={t('multiSearch.flights.threeDays')}
                                        control={
                                            <Switch
                                                size='small'
                                                color='secondary'
                                                checked={formData.threeDays}
                                                onChange={(e, checked) => handleChangeField('threeDays', checked)}
                                            />
                                        }
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} xl={11}>
                                <Grid container spacing={1} columns={24}>
                                    <Grid
                                        item
                                        xs={24}
                                        lg={16}
                                    >
                                        <Grid container spacing={1} rowSpacing={2}>
                                            {formData.segments.map((segment, i) => (
                                                <Grid item key={i} xs={12} className='row segment-container'>
                                                    <Hidden mdDown>
                                                        {formData.tripType === 'multidestination' && (
                                                            <div className='segment-number'>{i + 1}</div>
                                                        )}
                                                    </Hidden>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={24}
                                                        sx={formData.tripType === 'multidestination'
                                                            ? {
                                                                paddingLeft: {
                                                                    xs: 0,
                                                                    md: '40px'
                                                                }
                                                            }
                                                            : {}
                                                        }
                                                    >
                                                        <Grid
                                                            item
                                                            xs={24}
                                                            lg={15}
                                                            xl={formData.tripType === 'roundtrip' ? 16 : 20}
                                                            className='destinations-container'
                                                        >
                                                            <Grid container spacing={1}>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={6}
                                                                    sx={{
                                                                        position: 'relative',
                                                                        paddingRight: { xs: '32px', sm: '15px' }
                                                                    }}
                                                                >
                                                                    <FormLabel>{t('multiSearch.flights.origin')}</FormLabel>
                                                                    <Autocomplete
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        size="small"
                                                                        options={destinations || []}
                                                                        getOptionLabel={option => option.label}
                                                                        groupBy={(option) => option.category}
                                                                        value={segment?.origin || null}
                                                                        onChange={(e, value) => handleChangeSegmentField('origin', i, value)}
                                                                        noOptionsText={t('common.noOptions')}
                                                                        loadingText={`${t('common.loading')}...`}
                                                                        loading={loadingDestinations}
                                                                        disableClearable
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder={t('multiSearch.flights.selectOrigin')}
                                                                                onChange={(e) => handleChangeDestination(e.target.value)}
                                                                                error={errors.segments?.[i]?.origin}
                                                                                onBlur={handleBlurField}
                                                                            />
                                                                        }
                                                                        renderGroup={(params) => (
                                                                            <li {...params}>
                                                                                <span className='group-title'>
                                                                                    {t(`multiSearch.flights.${params.group}`)}
                                                                                </span>
                                                                                <ul className='group-option'>{params.children}</ul>
                                                                            </li>
                                                                        )}
                                                                    />
                                                                    <IconButton
                                                                        className='search-destinations-switcher'
                                                                        onClick={() => handleClickSwapDestinations(i)}
                                                                    >
                                                                        <SwapHoriz />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} style={{ paddingLeft: 24 }}>
                                                                    <FormLabel>{t('multiSearch.flights.destination')}</FormLabel>
                                                                    <Autocomplete
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        size="small"
                                                                        options={destinations || []}
                                                                        getOptionLabel={option => option.label}
                                                                        groupBy={(option) => option.category}
                                                                        value={segment?.destination || null}
                                                                        onChange={(e, value) => handleChangeSegmentField('destination', i, value)}
                                                                        noOptionsText={t('common.noOptions')}
                                                                        loadingText={`${t('common.loading')}...`}
                                                                        loading={loadingDestinations}
                                                                        disableClearable
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder={t('multiSearch.flights.selectDestination')}
                                                                                onChange={(e) => handleChangeDestination(e.target.value)}
                                                                                error={errors.segments?.[i]?.destination}
                                                                                onBlur={handleBlurField}
                                                                            />
                                                                        }
                                                                        renderGroup={(params) => (
                                                                            <li {...params}>
                                                                                <span className='group-title'>
                                                                                    {t(`multiSearch.flights.${params.group}`)}
                                                                                </span>
                                                                                <ul className='group-option'>{params.children}</ul>
                                                                            </li>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={24}
                                                            lg={9}
                                                            xl={formData.tripType === 'roundtrip' ? 8 : 4}
                                                        >
                                                            <Grid container spacing={1}>
                                                                <Grid
                                                                    item
                                                                    xs={formData.tripType === 'roundtrip' ? 6 : 12}
                                                                >
                                                                    <FormLabel>{t('multiSearch.flights.departure')}</FormLabel>
                                                                    <DesktopDatePicker
                                                                        open={openCheckin === i}
                                                                        onClose={() => setOpenCheckin(false)}
                                                                        onChange={(e) => handleChangeSegmentField('dateDeparture', i, e.format('L'))}
                                                                        showDaysOutsideCurrentMonth
                                                                        value={segment.dateDeparture ? dayjs(segment.dateDeparture, DATE_FORMAT) : null}
                                                                        className='pointer-input date-picker'
                                                                        minDate={dayjs()}
                                                                        renderDay={(day, _value, DayComponentProps) => getPickerDay(
                                                                            day,
                                                                            _value,
                                                                            DayComponentProps,
                                                                            segment,
                                                                            i
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                id='checkin-input'
                                                                                focused={false}
                                                                                placeholder={DATE_FORMAT}
                                                                                value={segment.dateDeparture ? segment.dateDeparture : ''}
                                                                                inputProps={{ readOnly: true }}
                                                                                size='small'
                                                                                fullWidth
                                                                                error={errors.segments?.[i]?.dateDeparture}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start">
                                                                                            <DateRange />
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }}
                                                                                onClick={() => {
                                                                                    setOpenCheckout(null)
                                                                                    setOpenCheckin(i)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                {formData.tripType === 'roundtrip' && (
                                                                    <Grid item xs={6}>
                                                                        <FormLabel>{t('multiSearch.flights.return')}</FormLabel>
                                                                        <DesktopDatePicker
                                                                            open={openCheckout === i}
                                                                            onClose={() => setOpenCheckout(false)}
                                                                            onChange={(e) => handleChangeSegmentField('dateArrive', i, e.format('L'))}
                                                                            showDaysOutsideCurrentMonth
                                                                            value={segment.dateArrive
                                                                                ? dayjs(segment.dateArrive, DATE_FORMAT)
                                                                                : segment.dateDeparture ? dayjs(segment.dateDeparture, DATE_FORMAT) : null
                                                                            }
                                                                            className='pointer-input date-picker'
                                                                            minDate={dayjs()}
                                                                            renderDay={(day, _value, DayComponentProps) => getPickerDay(
                                                                                day,
                                                                                _value,
                                                                                DayComponentProps,
                                                                                segment,
                                                                                i
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    id='checkout-input'
                                                                                    focused={false}
                                                                                    placeholder={DATE_FORMAT}
                                                                                    value={segment.dateArrive ? segment.dateArrive : ''}
                                                                                    inputProps={{ readOnly: true }}
                                                                                    size='small'
                                                                                    fullWidth
                                                                                    error={errors.segments?.[i]?.dateArrive}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <DateRange />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setOpenCheckin(null)
                                                                                        setOpenCheckout(i)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                )}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {i > 0 && (
                                                        <IconButton className='delete-segment-button' onClick={() => handleRemoveSegment(i)}>
                                                            <Delete />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={24}
                                        sm={16}
                                        md={12}
                                        lg={5}
                                        xl={5}
                                    >
                                        <FormLabel>{t('multiSearch.flights.passengers')}</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onClick={handleClickGuests}
                                            className='pointer-input'
                                            value={getPassengersValue()}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Person />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end" style={{ marginLeft: '6px' }}>
                                                        {Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
                                                    </InputAdornment>
                                                ),
                                                readOnly: true
                                            }}
                                        />
                                        <FormHelperText
                                            error={true}
                                            sx={{ display: errors.noPassengers ? 'block' : 'none' }}
                                        >
                                            {errors.noPassengers ? t('multiSearch.flights.atLeastOnePassenger') : <>&nbsp;</>}
                                        </FormHelperText>
                                        <Popover
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={handleCloseGuests}
                                            className='flights-guests-popover'
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Grid p={2}>
                                                <Grid className='passengers-input'>
                                                    <div>
                                                        <Typography className='title'>{t('multiSearch.flights.adults')}</Typography>
                                                        <Typography className='subtitle'>
                                                            {t('multiSearch.flights.fromYears', { from: 11 })}
                                                        </Typography>
                                                    </div>

                                                    <div className='buttons-container row'>
                                                        <IconButton
                                                            disabled={formData.adults === 0}
                                                            onClick={() => handleChangeField('adults', formData.adults - 1)}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                        <span>{formData.adults}</span>
                                                        <IconButton
                                                            disabled={formData.adults === getMaxPassengers('adults')}
                                                            onClick={() => handleChangeField('adults', formData.adults + 1)}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                </Grid>

                                                <Divider />

                                                <Grid className='passengers-input'>
                                                    <div>
                                                        <Typography className='title'>{t('multiSearch.flights.kids')}</Typography>
                                                        <Typography className='subtitle'>
                                                            {t('multiSearch.flights.fromToYears', { from: 2, to: 11 })}
                                                        </Typography>
                                                    </div>

                                                    <div className='buttons-container row'>
                                                        <IconButton
                                                            disabled={formData.kids === 0}
                                                            onClick={() => handleChangeField('kids', formData.kids - 1)}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                        <span>{formData.kids}</span>
                                                        <IconButton
                                                            disabled={formData.kids === getMaxPassengers('kids')}
                                                            onClick={() => handleChangeField('kids', formData.kids + 1)}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                </Grid>

                                                <Divider />

                                                <Grid className='passengers-input'>
                                                    <div>
                                                        <Typography className='title'>{t('multiSearch.flights.babys')}</Typography>
                                                        <Typography className='subtitle'>
                                                            {t('multiSearch.flights.fromToMonths', { from: 0, to: 24 })}
                                                        </Typography>
                                                    </div>

                                                    <div className='buttons-container row'>
                                                        <IconButton
                                                            disabled={formData.babys === 0}
                                                            onClick={() => handleChangeField('babys', formData.babys - 1)}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                        <span>{formData.babys}</span>
                                                        <IconButton
                                                            disabled={formData.babys === formData.adults}
                                                            onClick={() => handleChangeField('babys', formData.babys + 1)}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                </Grid>

                                                <Grid container>
                                                    <Typography className='minor-ages-text'>
                                                        {t('multiSearch.flights.minorAges')}
                                                    </Typography>
                                                </Grid>

                                                <Grid container justifyContent="flex-end">
                                                    <Button size='small' variant="contained" color="secondary" onClick={handleCloseGuests} >
                                                        {t('multiSearch.flights.apply')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Popover>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={24}
                                        sm={8}
                                        md={12}
                                        lg={3}
                                    >
                                        <FormLabel>{t('multiSearch.flights.currency')}</FormLabel>
                                        <Select
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            className='currency-input'
                                            value={formData.currency}
                                            onChange={e => handleChangeField('currency', e.target.value)}
                                        >
                                            <MenuItem value='USD'><span className='currency'>U$S</span>&nbsp;USD</MenuItem>
                                            <MenuItem value='EUR'><span className='currency'>€</span>&nbsp;EUR</MenuItem>
                                            <MenuItem value='ARS'><span className='currency'>$</span>&nbsp;ARS</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Hidden xlDown>
                                <Grid item xs={12} xl={1}>
                                    {getSubmitButton()}
                                </Grid>
                            </Hidden>

                            <Grid container className='add-segment-container'>
                                <Grid item xs={12} sm={6}>
                                    {formData.segments.length < 3 && (
                                        <Button startIcon={<AddBox />} onClick={handleAddSegment}>
                                            {t('multiSearch.flights.addSegment')}
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} className='advanced-search-container'>
                                    <Button
                                        endIcon={showAdvancedSearch ? <ArrowDropUp /> : <ArrowDropDown />}
                                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                                    >
                                        {t('multiSearch.flights.advancedSearch')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion expanded={showAdvancedSearch} className='advanced-search-accordion'>
                                        <AccordionSummary />
                                        <AccordionDetails className='mt-05 mb-05'>
                                            <Grid container spacing={1} columns={24}>
                                                <Grid item xs={24} md={12} lg={8} xl={6}>
                                                    <FormLabel>{t('multiSearch.flights.airline')}</FormLabel>
                                                    <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        placeholder={t('multiSearch.flights.selectAirline')}
                                                    />
                                                </Grid>
                                                <Grid item xs={24} sm={12} md={5} xl={4}>
                                                    <FormLabel>{t('multiSearch.flights.scaleType')}</FormLabel>
                                                    <Select
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        value={formData.scaleType}
                                                        onChange={e => handleChangeField('scaleType', e.target.value)}
                                                    >
                                                        <MenuItem value='direct'>Directo</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={24} sm={12} md={5} xl={4}>
                                                    <FormLabel>{t('multiSearch.flights.luggage')}</FormLabel>
                                                    <Select
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        value={formData.luggage}
                                                        onChange={e => handleChangeField('luggage', e.target.value)}
                                                    >
                                                        <MenuItem value='included'>Con equipaje incluido</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Hidden xlUp>
                                    <Grid item xs={12} display='flex' justifyContent='flex-end'>
                                        {getSubmitButton()}
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default MultibuscadorFlights;
