import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import { Flight, CalendarToday, AirplaneTicketOutlined, ExpandMore, StarOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import FlightScales from './FlightScales';
import { ReactComponent as BaggageImage } from '../../../assets/images/baggage/baggage.svg';
import { ReactComponent as SmallBaggage } from '../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../assets/images/baggage/largeBaggage.svg';
import { connect } from 'react-redux';
import { availabilityActionFlights } from '../../../store/actions';
import AvailabilityModal from './AvailabilityModal';
import Preloader from '../../common/Preloader';
import Image from '../../common/Image';
import { useClientData } from '../../../context/ClientContext';

const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';
const CABIN_CLASS = {
  Y: 'economy',
  P: 'premium',
  B: 'business',
  F: 'firstClass'
};
const PAX_TYPES = {
  ADT: { single: 'adult', multiple:'adults' },
  CHD: { single: 'kid', multiple:'kids' },
  INF: { single: 'baby', multiple:'babys' }
};

const FlightCard = ({
  client,
  tripType,
  recommendationID,
  flight,
  disabledCompare,
  selectedToCompare,
  handleClickCompare,
  resultsAvailability,
  errorAvailability,
  ...props
}) => {
  const { t } = useTranslation();
  const { clientData } = useClientData();
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [availability, setAvailability] = useState();

  useEffect(() => {
    const firstOptions = flight.Legs.map(elem => elem.Options?.[0]?.FlightOptionID);
    setSelectedFlights(firstOptions);
  }, [flight]);

  useEffect(() => {
    if (loadingAvailability && resultsAvailability?.status) {
      setLoadingAvailability(false);
      setAvailability(resultsAvailability.data);
      setOpenModal(resultsAvailability?.data?.AlternativeFares?.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsAvailability]);

  useEffect(() => {
    if (errorAvailability) {
      setLoadingAvailability(false);
      setAvailability(null);
    }
  }, [errorAvailability]);

  const getLegTitle = (leg) => {
    const segmentsLength = leg?.Options?.[0]?.Segments?.length;
    const departure = leg?.Options?.[0]?.Segments?.[0]?.Departure?.CityName;
    const arrival = leg?.Options?.[0]?.Segments?.[segmentsLength - 1]?.Arrival?.CityName;
    return `${departure} - ${arrival}`
  }

  const getDate = (leg) => {
    const date = leg?.Options?.[0]?.Segments?.[0]?.Departure?.Date;
    return dayjs(date, DATE_FORMAT_INTERNATIONAL).format('dddd, DD MMM YY');
  }

  const getCabinClass = (leg) => {
    const cabinClass = CABIN_CLASS[leg?.Options?.[0]?.Segments?.[0]?.CabinClass];
    if (cabinClass) {
      return t(`results.flights.${cabinClass}`);
    }
  }

  const getScalesTooltip = (segments) => {
    if (segments.length === 1) {
      return '';
    }

    return <>
      {segments.slice(1).map((segment, i) => (
        <Grid key={i}>
          <Typography><b>{segment.Departure.CountryName} ({segment.Departure.CityCode})</b></Typography>
          <Typography>
            {t('results.flights.scaleWait', {
              city: segment.Departure.CityName,
              hours: segment.WaitHours,
              minutes: segment.WaitMinutes
            })}
          </Typography>
        </Grid>
      ))}
    </>
  }

  const getSegmentsDuration = (segments) => {
    return <>
    {segments.map((segment, i) => {
      const hours = Math.floor(segment.Duration / 60);
      const minutes = segment.Duration % 60;

      return <Fragment key={i}>
        {i > 0 && (
          <Grid className='row' justifyContent='space-between'>
            <Typography style={{ marginRight: 20 }}>
              {t('results.flights.scaleIn', { airport: segment.Departure.AirportCode })}
            </Typography>
            <Typography>{segment.WaitHours}h {segment.WaitMinutes}m</Typography>
          </Grid>
        )}

        <Grid className='row' justifyContent='space-between'>
          <Typography style={{ marginRight: 20 }}>
            <b>{segment.Departure.AirportCode} - {segment.Arrival.AirportCode}</b>
          </Typography>
          <Typography><b>{hours}h {minutes}m</b></Typography>
        </Grid>
      </Fragment>
    })}
  </>
  }

  const getDaysAfterTooltip = (days) => {
    return days === 1
      ? t('results.flights.dayAfter')
      : t('results.flights.daysAfter', { days });
  }

  const getBaggages = (option, vertical = true) => {
    const baggages = [
      {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      }
    ];

    const mediumBaggage = {
      icon: <MediumBaggage className='not-included' />,
      title: t('results.flights.mediumBaggageNotIncluded')
    };
    const largeBaggage = {
      icon: <LargeBaggage className='not-included' />,
      title: t('results.flights.largeBaggageNotIncluded')
    };

    if (!['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      const description = [];

      if (option?.Segments?.[0]?.Baggage?.includes('PC')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('PC');
        const pieces = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        })
      } else if (option?.Segments?.[0]?.Baggage?.includes('K')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('K');
        const kg = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t('results.flights.pieceKgPerPassenger', { kg, passenger: passenger.toLowerCase() }));
        })
      }

      description.push(t('results.flights.largeBaggageDescription'));

      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />

      largeBaggage.title = t('results.flights.largeBaggageIncluded');
      largeBaggage.description = description.join(' ');
      largeBaggage.icon = <LargeBaggage />
    }

    baggages.push(mediumBaggage);
    baggages.push(largeBaggage);

    return <Grid container className='baggage-container' spacing={1}>
      {baggages.map((elem, i) => (
        <Grid item xs={vertical ? 12 : 4}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baddage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageIcons = (option) => {
    const mediumLargeBaggageClassName = ['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage) ? 'not-included' : '';

    return <Tooltip title={getBaggages(option)}>
      <SmallBaggage />
      <MediumBaggage className={mediumLargeBaggageClassName} />
      <LargeBaggage className={mediumLargeBaggageClassName} />
    </Tooltip>
  }

  const getOptionContainer = (option) => {
    const segmentsLength = option?.Segments?.length;
    const departure = option?.Segments?.[0]?.Departure;
    const arrival = option?.Segments?.[segmentsLength - 1]?.Arrival;
    const provider = option?.Segments?.[0]?.Airline;

    return <Grid container columns={24}>
      <Grid item className='row' xs={3}>
        <Image img={`providersFlights/${provider}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={departure?.AirportName || ''}>
          <Typography className='title'>{departure?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{departure?.Time}</Typography>
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={getScalesTooltip(option?.Segments)} placement='top'>
          <Typography className={`scales-text ${segmentsLength === 1 && 'direct'}`}>
            {segmentsLength > 1 && <>{segmentsLength - 1}&nbsp;</>}
            {t(`results.flights.${segmentsLength === 1 ? 'direct' : segmentsLength === 2 ? 'scale' : 'scales'}`)}
          </Typography>
        </Tooltip>
        <div className='scale-line'>
          <div className='circles-container row'>
            {option?.Segments.slice(1).map((elem, i) => <div key={i} className='circle' />)}
          </div>
        </div>
        <span>
          <Tooltip title={getSegmentsDuration(option?.Segments)} placement='bottom'>
            <span className='duration'>{option.OptionDurationHours}h {option.OptionDurationMinutes}m</span>
          </Tooltip>
          {option.DayAfter > 0 && (
            <Tooltip title={getDaysAfterTooltip(option.DayAfter)} placement='bottom'>
              <span className='plus-days'>+{option.DayAfter}D</span>
            </Tooltip>
          )}
        </span>
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={arrival?.AirportName || ''}>
          <Typography className='title'>{arrival?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{arrival?.Time}</Typography>
      </Grid>

      <Grid item className='row baggage-container-icons' xs={3}>
        {getBaggageIcons(option)}
      </Grid>
    </Grid>
  }

  const handleSelectOption = (event, index, optionId) => {
    event.stopPropagation();

    let selectedFlightsAux = [...selectedFlights];
    selectedFlightsAux[index] = optionId;
    setSelectedFlights(selectedFlightsAux);
  }

  const handleClickMoreRates = () => {
    const token = clientData?.credential?.api?.tokenFlights;
    const dataToSend = {
      recommendationID,
      fareID: flight.FareID,
      optionID: selectedFlights,
      flightType: tripType
    };
    props.getAvailability(token, dataToSend);
    setLoadingAvailability(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  // const getPriceByPax = (paxType) => {
  //   const fare = flight.PaxFares.find(elem => elem.PaxType === paxType)?.PaxFareAmount || 0;
  //   const fee = flight.ExtendedFareInfo.Fee.PaxDetail.find(elem => elem.PTC === paxType)?.AmountPerPax || 0;
  //   const commission = flight.ExtendedFareInfo.Commission.PaxDetail.find(elem => elem.PTC === paxType)?.AmountPerPax || 0;
  //   const over = flight.ExtendedFareInfo.Over.PaxDetail.find(elem => elem.PTC === paxType)?.AmountPerPax || 0;
  //   const tax = flight.PaxFares.find(elem => elem.PaxType === paxType)?.PaxTaxAmount || 0;

  //   return fare + tax + fee - commission - over;
  // }

  const getTaxPrice = () => {
    return + flight.TaxAmount
      + flight.ExtendedFareInfo.Fee.Amount
      - flight.ExtendedFareInfo.Commission.Amount
      - flight.ExtendedFareInfo.Over.Amount
  }

  const getTotalPrice = () => {
    return flight.FareAmount
      + flight.TaxAmount
      + flight.ExtendedFareInfo.Fee.Amount
      - flight.ExtendedFareInfo.Commission.Amount
      - flight.ExtendedFareInfo.Over.Amount
  }

  const getPrice = (flight) => {
    return <>
      {flight.PaxFares.map((elem, i) => {
        const type = PAX_TYPES[elem.PaxType];
        const name = elem.Count === 1 ? type.single : type.multiple;

        return <div className='row' key={i}>
          <Typography>{elem.Count} {t(`results.flights.${name}`)}</Typography>
          <Typography>{flight.Currency} {parseInt(elem.PaxFareAmount * elem.Count)}</Typography>
        </div>
      })}
      <div className='row'>
        <Typography>{t('results.flights.taxes')}</Typography>
        <Typography>{flight.Currency} {parseInt(getTaxPrice())}</Typography>
      </div>
      <div className='row total'>
        <Typography>{t('results.flights.total')}</Typography>
        <Typography>{flight.Currency} {parseInt(getTotalPrice())}</Typography>
      </div>
    </>
  }

  return (
    <Grid className={`flight-card ${flight.recommended ? 'recommended' : ''}`}>
      {flight.recommended && (
        <Grid className='row recommended-container'>
          <StarOutline /> {t('results.flights.flightRecommended')}
        </Grid>
      )}

      <Grid container className='flight-content'>
        <Grid item xs={8} className='left'>
          {flight.Legs.map((leg, i) => {
            const isReturn = tripType === 'roundtrip' && leg.LegNumber === 2;
            return <Grid key={i} className='leg-container'>
              <Grid className='row leg-title-container'>
                <Grid className='left row'>
                  <Grid className='row trip-type-container'>
                    <Flight className={`flight-icon ${isReturn && 'return'}`} />
                    {isReturn ? t('results.flights.return') : t('results.flights.departure')}
                  </Grid>
                  <Typography className='title'>{getLegTitle(leg)}</Typography>
                  <Typography className='row date'>
                    <CalendarToday fontSize='small' /> {getDate(leg)}
                  </Typography>
                </Grid>
                <Grid className='row cabin-class'>
                  <AirplaneTicketOutlined /> {getCabinClass(leg)}
                </Grid>
              </Grid>
              <Grid className='leg-options-container'>
              <FormControl fullWidth>
                <RadioGroup value={selectedFlights[i] || null}>
                  {leg.Options.map((option, j) => (
                    <Accordion key={j} className='option-accordion'>
                      <AccordionSummary expandIcon={<ExpandMore />} onClick={e => e.preventDefault()}>
                        <FormControlLabel
                          value={option.FlightOptionID}
                          control={<Radio onClick={e => handleSelectOption(e, i, option.FlightOptionID)} />}
                          label={getOptionContainer(option)}
                          className='option-container'
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <FlightScales cabinClass={getCabinClass(leg)} option={option} />
                        {getBaggages(option, false)}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </RadioGroup>
              </FormControl>
              </Grid>
            </Grid>
          })}
          <Grid container className='more-rates-container'>
            <Grid item xs={12} md={6} className='row'>
              <BaggageImage className='baggage-image' />
              <Typography className='text'>{t('results.flights.improveFlight')}</Typography>
            </Grid>
            <Grid item xs={12} md={6} className='row align-right'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={handleClickMoreRates}
              >
                {t('results.flights.seeMoreRates')} <ExpandMore />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} className='right'>
          <Grid className='top row'>
            <div>
              <Image
                img={`fareSupplierFlights/${flight?.FareSupplierCode}.png`}
                alt='logo-provider'
                className='provider-logo'
                hideBrokenImage
              />
            </div>
            {/* <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  disabled={!selectedToCompare && disabledCompare}
                  checked={selectedToCompare}
                  onChange={(e, checked) => handleClickCompare && handleClickCompare(checked)}
                />
              }
              label={t('results.flights.compare')}
              className='compare-checkbox'
            /> */}
          </Grid>

          <Grid className='bottom column'>
            <Grid className='fare-type-container'>
              {flight.FareType === 'N' ? t('results.flights.privateFare') : t('results.flights.publicFare')}
            </Grid>
            <Typography className='total-price-text'>{t('results.flights.totalPrice')}</Typography>
            <Typography className='amount'>{flight.Currency} {parseInt(getTotalPrice())}</Typography>
            <Typography className='amount-passenger'>{t('results.flights.priceByPassenger')} {flight.Currency} {parseInt(flight.AverageAmount)}</Typography>
            <Accordion className='detail-accordion'>
              <AccordionSummary expandIcon={<ExpandMore />}>
                {t('results.flights.seeDetail')}
              </AccordionSummary>
              <AccordionDetails>
                {getPrice(flight)}
              </AccordionDetails>
            </Accordion>
            <Button
              size='small'
              variant='contained'
              color='secondary'
              className='reserve-button'
            >
              {t('common.reserve')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {loadingAvailability && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadHotel.gif`}
          text={t('common.searchingBestPrices')}
        />
      )}

      <AvailabilityModal
        open={openModal}
        availability={availability}
        handleClose={handleCloseModal}
      />
    </Grid>
  );
};

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailability: (token, data, signalAbort = null) => dispatch(availabilityActionFlights(token, data, signalAbort))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightCard);
