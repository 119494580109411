import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Table, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { searchActionFlights } from '../../../store/actions';
import { connect } from 'react-redux';
import { KeyboardArrowDown, Search } from '@mui/icons-material';

function ThreeDaysTable({ results, loading }) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const isSelectedDate = (dayA, dayB) => {
    return dayjs(dayA).isSame(dayjs(dayB), 'date');
  }

  const isClickable = (departureDate, arrivalDate) => {
    return dayjs(departureDate).isSameOrAfter(dayjs(), 'date')
      && dayjs(arrivalDate).isAfter(dayjs(), 'date')
      && (!dayjs(departureDate).isSame(dayjs(results?.originalSearch?.departure), 'date')
        || !dayjs(arrivalDate).isSame(dayjs(results?.originalSearch?.arrival), 'date'))
      && dayjs(departureDate).isBefore(dayjs(arrivalDate), 'date');
  }

  const getClassNameCell = (departure, arrival) => {
    const departureSearch = results?.originalSearch?.departure;
    const arrivalSearch = results?.originalSearch?.arrival;
    let className = [];

    if (isClickable(departure, arrival)) {
      className.push('price-cell');
    }

    if (dayjs(departure).isSame(dayjs(departureSearch), 'date') && dayjs(arrival).isSame(dayjs(arrivalSearch), 'date')) {
      className.push('selected-price');
    }

    if (dayjs(departure).isSameOrBefore(dayjs(departureSearch), 'date')
      && dayjs(arrival).isBetween(dayjs(arrivalSearch).subtract(1, 'day'), dayjs(arrivalSearch), 'date', '[]')
    ) {
      className.push('border-bottom-price');
    }

    if (dayjs(arrival).isSameOrBefore(dayjs(arrivalSearch), 'date')
      && dayjs(departure).isBetween(dayjs(departureSearch).subtract(1, 'day'), dayjs(departureSearch), 'date', '[]')
    ) {
      className.push('border-right-price');
    }

    return className.join(' ');
  }

  const onClickPrice = (departureDate, arrivalDate) => {
    if (isClickable(departureDate, arrivalDate)) {
      const { tripType, origins, destinations, adults, kids, babys, cabinClasses } = params;
      const departure = dayjs(departureDate).format('DDMMYY');
      const arrival = dayjs(arrivalDate).format('DDMMYY');
      const url = `/vuelos/resultados/${tripType}/${origins}/${destinations}/${departure}/${arrival}/${adults}/${kids}/${babys}/NA/NA/NA/${cabinClasses}/NA/0`;
      navigate(url);
    }
  }

  const showPrice = (departureDate, arrivalDate) => {
    return isClickable(departureDate, arrivalDate)
      || (departureDate === results?.originalSearch?.departure
        && arrivalDate === results?.originalSearch?.arrival
      )
  }

  return (
    <Accordion className='three-days-accordion' disabled={loading}>
      <AccordionSummary expandIcon={loading ? <CircularProgress size={20} /> : <KeyboardArrowDown />}>
        <Typography className='title'>{t('results.flights.threeDaysPrices')}</Typography>
        <Typography className='message'>{t('results.flights.threeDaysMessage')}</Typography>
      </AccordionSummary>

      {!loading && results && (
        <AccordionDetails>
          <Table size='small'>
            <TableRow>
              <TableCell className='first-cell'>
                <span>{t('results.flights.departure')}</span>
                <span>{t('results.flights.return')}</span>
              </TableCell>
              {results?.departureDates?.map((departureDate, i) => (
                <TableCell
                  key={i}
                  align='center'
                  className={isSelectedDate(departureDate, results?.originalSearch?.departure) ? 'selected-date' : ''}
                >
                  <p>{dayjs(departureDate).format('dddd')}</p>
                  <p className='date'>{dayjs(departureDate).format('DD MMM')}.</p>
                </TableCell>
              ))}
            </TableRow>
            {results?.returnDates?.map((returnDate, i) => (
              <TableRow>
                <TableCell
                  key={i}
                  align='center'
                  className={isSelectedDate(returnDate, results?.originalSearch?.arrival) ? 'selected-date' : ''}
                >
                  <p>{dayjs(returnDate).format('dddd')}</p>
                  <p className='date'>{dayjs(returnDate).format('DD MMM')}.</p>
                </TableCell>
                {results?.departureDates?.map((departureDate, j) => (
                  <TableCell
                    key={j}
                    align='center'
                    className={getClassNameCell(departureDate, returnDate)}
                    onClick={() => onClickPrice(departureDate, returnDate)}
                  >
                    {showPrice(departureDate, returnDate) && (
                      results?.prices?.[departureDate]?.[returnDate]?.value ? (
                        <span>{results?.currency} {parseInt(results?.prices?.[departureDate]?.[returnDate]?.value || 0)}</span>
                      ) : (
                        <Search sx={{ marginTop: '8px' }} />
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Table>
        </AccordionDetails>
      )}

    </Accordion>
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    searchProducts: (token, dataForm, signalAbort = null) => dispatch(searchActionFlights(token, dataForm, signalAbort)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreeDaysTable);
