import React, { Fragment } from 'react';
import { Avatar, Badge, Card, CardContent, CardHeader, Grid, Hidden, Icon, Typography } from '@mui/material';
import DetailDialog from './DetailDialog';
import * as HotelsDetail from './utils/HotelsDetail';
import * as TrainsDetail from './utils/TrainsDetail';
import { useTranslation } from 'react-i18next';

const PurchaseDetail = (props) => {
  const { t } = useTranslation();

  const getProductsDetails = (products) => {
    if (props.module === 'hotels') {
      return HotelsDetail.getProductsDetails(products);
    } else if (props.module === 'trains') {
      return TrainsDetail.getProductsDetails(products);
    }
  }

  const getPriceDetails = () => {
    if (props.module === 'trains') {
      return TrainsDetail.getPriceDetails(props.purchaseDetail);
    }

    return [
      {
        title: 'total',
        value: props.purchaseDetail?.totalAmount,
        classname: 'strong amount',
        showTax: true
      }
    ];
  }

  return (
    <Card className='purchase-card'>
      <CardHeader
        avatar={
          <Avatar className='avatar-purchase-detail'>
            <Badge
              badgeContent={props.purchaseDetail?.priceDetail?.length || 0}
              color='primary'
              overlap='circular'
            >
              <Icon>{props.purchaseDetail?.icon}</Icon>
            </Badge>
          </Avatar>
        }
        action={props.products &&
          <DetailDialog
            icon='expand_more'
            purchaseDetail={props.purchaseDetail}
            products={props.products}
            module={props.module}
          />
        }
        title={
          <Typography className='title-card-header'>
            {t('checkout.common.purchaseDetailTitle')}
          </Typography>
        }
        subheader={
          <Typography noWrap className='title-card-subheader' >
						{props.purchaseDetail?.subtitle}
          </Typography>
        }
      />

      <Hidden smDown>
        <CardContent>
          {getProductsDetails(props.purchaseDetail?.priceDetail)}
        </CardContent>
      </Hidden>

      <Grid container className='price-details-container row'>
        {getPriceDetails().map((elem, i) => (
          <Fragment key={i}>
            {elem.value > 0 && (
              <>
                <Grid item xs={7} className='left'>
                  <Typography className={elem.classname}>{t(`checkout.common.${elem.title}`)}</Typography>
                  {elem.showTax && Boolean(props.purchaseDetail?.taxAmount) && props.purchaseDetail?.taxAmount > 0 && (
                    <Typography className='taxes'>
                      {t('checkout.common.taxesIncluded')} {props.purchaseDetail?.currency} {props.purchaseDetail?.taxAmount}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={5} className='right'>
                  <Typography className={`text-right ${elem.classname}`}>
                    {props.purchaseDetail?.currency} {elem.value}
                  </Typography>
                </Grid>
              </>
            )}
          </Fragment>
        ))}
      </Grid>
    </Card>
  )
}

export default PurchaseDetail;
