import React, { useEffect } from 'react';
import Navbar from '../../layout/Navbar';
import { useNavigate, useParams } from 'react-router';
import { checkToken } from '../../../store/services/Login';
import AllBanners from '../../common/Banners/AllBanners';
import { useBannersData } from '../../../context/BannersContext';
import { useClientData } from '../../../context/ClientContext';

function HomePage() {
  const navigate = useNavigate();
  const params = useParams();
  const { bannersData } = useBannersData();
  const { setClientData, updateProductToken } = useClientData();

  useEffect(() => {
    ;(async () => {
      try {
        const token = localStorage.getItem('jwt');
        await checkToken(token);
        updateProductToken(['air_detail']);
      } catch (error) {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      }
    })();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flights-home homepage">
      <Navbar />

      <AllBanners
        banners={bannersData?.data?.modules?.['AEREOS']?.flows?.home?.banners || {}}
        titlePromotions='Sumá más productos a tu reserva de vuelo'
        titleOffers='Las mejores ofertas de vuelos por el mundo'
        titleFeatured='Las mejores ofertas de vuelos por el mundo'
      />
    </div>
  );
}

export default HomePage;
