import React from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Hidden, Icon, IconButton, Rating, Tooltip, Typography } from '@mui/material';
import ImagesSlider from '../../common/ImagesSlider';
import { Favorite, FavoriteBorder, LocalCafe, LocalHotel, LocationOn, NearMe, RoomService, AttachMoney } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Dots from '../../common/Dots';

const AMENITIES = [
  { code: 14040, icon: 'wifi' },
  { code: 11890, icon: 'local_parking' },
  { code: 13530, icon: 'pool' },
  { code: 10080, icon: 'ac_unit' },
  { code: 12810, icon: 'pets' },
  { code: 13840, icon: 'tv' }
];

const BOARDBASES = {
  'Todo Incluido': 'allInclusive',
  'Solo Habitación': 'onlyRoom',
  'Desayuno': 'breakfast',
  'Media Pension': 'halfPension'
};

const HotelCard = ({
  hotel,
  disabledCompare,
  selectedToCompare,
  provider,
  isFavorite,
  showDistance,
  destination,
  hashFlow,
  handleClickCompare,
  handleClickFavorite,
  ...props
}) => {
  const { t } = useTranslation();

  const getPriceInfo = () => {
    return (
      <>
        <Typography>{t('results.hotels.totalPriceFrom')}</Typography>
        <Typography className='total-price'>
          {!Boolean(hashFlow) ? <Dots number={3} /> : <>{hotel.currency} {parseInt(hotel.totalPrice)}</>}
        </Typography>
        <Typography className='avg price row'>
          {hotel.currency} {!Boolean(hashFlow) ? <>&nbsp;</> : parseInt(hotel.avgPrice)} {t('results.hotels.pricePerNight')}
        </Typography>
        <Button
          size='small'
          variant='contained'
          color='secondary'
          disabled={!Boolean(hashFlow)}
          onClick={props.handleClickShowRooms}
        >
          {t('results.hotels.moreRates')}
        </Button>
        {provider ? (
          <Tooltip title={hotel.providerName}>
            <img className='provider-logo' src={provider} alt='logo-provider' />
          </Tooltip>
        ) : (
          <Typography>{hotel.providerName}</Typography>
        )}
      </>
    )
  }

  const getPriceInfoMobile = () => {
    return (
      <Grid container>
        <Grid item xs={6} sm={7}>
          <Typography>{t('results.hotels.totalPriceFrom')}</Typography>
          <Typography className='total-price'>{hotel.currency} {parseInt(hotel.totalPrice)}</Typography>
          <Typography className='avg price'>{hotel.currency} {parseInt(hotel.avgPrice)} {t('results.hotels.pricePerNight')}</Typography>
        </Grid>

        <Grid item xs={6} sm={5} className='column'>
          <Button size='small' variant='contained' color='secondary' onClick={props.handleClickShowRooms}>
            {t('results.hotels.moreRates')}
          </Button>
          {provider ? (
            <Tooltip title={hotel.providerName}>
              <img className='provider-logo' src={provider} alt='logo-provider' />
            </Tooltip>
          ) : (
            <Typography>{hotel.providerName}</Typography>
          )}
          <Typography className='row cancelation'>
            {hotel.rateType === 'Refundable' ? (
              <><AttachMoney className='money-icon green' /> {t('results.hotels.noCancellationFees')}</>
            ) : <><AttachMoney className='money-icon red' />  {t('results.hotels.withCancellationFees')}</>}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const getDistanceText = () => {
    return t('results.hotels.distanceFromReference', { distance: hotel.toReference, destination });
  }

  return (
    <Grid className={`hotel-card ${hotel.recommended ? 'recommended' : ''}`}>
      {hotel.recommended && (
        <Grid className='row recommended-container'>
          <RoomService /> {t('results.hotels.hotelRecommended')}
        </Grid>
      )}

      <Grid container className='hotel-content'>
        <Grid item className='image-container' xs={4} lg={3} xl={4} key={hotel.id}>
          <Hidden smDown>
            <ImagesSlider images={hotel.images?.slice(0, 5)} height='100%' />
          </Hidden>
          <Hidden smUp>
            <img className='image-small' alt='hotel' src={hotel.image} />
          </Hidden>

          <IconButton className='favorite-button' onClick={() => handleClickFavorite && handleClickFavorite(hotel, !isFavorite)}>
            {isFavorite ? <Favorite className='favorite-icon' /> : <FavoriteBorder />}
          </IconButton>
        </Grid>

        <Grid container item className='row info-container' xs={8} lg={9} xl={8}>
          <Grid item className='left-side' xs={12} md={8}>
            <Typography className='property'>
              {t(`filters.hotels.properties.${hotel?.property?.toLowerCase() || 'other'}`)}
            </Typography>
            <Tooltip title={hotel.name}>
              <Typography className='name'>{hotel.name}</Typography>
            </Tooltip>
            <Grid className='row address'>
              <Typography className='row location'>
                <LocationOn fontSize='small' />
                <Tooltip title={hotel.address}><span>{hotel.address}</span></Tooltip>
              </Typography>
              {showDistance && hotel.toReference !== undefined && (
                <Tooltip title={getDistanceText()}>
                  <Typography className='row distance'>
                    <NearMe fontSize='small' /> <span>{getDistanceText()}</span>
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid className='row'>
              <Rating value={parseInt(hotel.stars)} readOnly />
            </Grid>
            <Grid className='buttons-container'>
              <Button
                size='small'
                onClick={props.handleClickShowDetail}
                disabled={!Boolean(hashFlow)}
              >
                {t('results.hotels.seeHotelDetails')}
              </Button>
              <Button size='small' onClick={props.handleClickShowMap}>
                {t('results.hotels.seeHotelInMap')}
              </Button>
            </Grid>
            <Grid className='amenities'>
              {AMENITIES
                .filter(amenity => hotel.amenities.find(elem => elem.code === amenity.code))
                .map((amenity, i) => (
                  <Icon key={i}>{amenity.icon}</Icon>
                ))
              }
              &nbsp;
            </Grid>
            <Grid className='buttons-container'>
              <Typography className='row boardbases'>
                {hotel.boardbases.includes('Solo Habitacion') ? (
                  <><LocalHotel /> {t('results.hotels.onlyRoom')}</>
                ) : <><LocalCafe /> {BOARDBASES[hotel.boardbases[0]]
                  ? t(`results.hotels.${BOARDBASES[hotel.boardbases[0]]}`)
                  : hotel.boardbases[0]
                }</>}
              </Typography>
              <Typography className='row cancelation'>
                {hotel.rateType === 'Refundable' ? (
                  <><AttachMoney className='money-icon green' /> {t('results.hotels.noCancellationFees')}</>
                ) : <><AttachMoney className='money-icon red' />  {t('results.hotels.withCancellationFees')}</>}
              </Typography>
            </Grid>
          </Grid>

          <Hidden mdDown>
            <Grid item className='right-side' md={4}>
              {getPriceInfo()}
            </Grid>
          </Hidden>

          <FormControlLabel
            control={
              <Checkbox
                size='small'
                disabled={!selectedToCompare && disabledCompare}
                checked={selectedToCompare}
                onChange={(e, checked) => handleClickCompare && handleClickCompare(checked)}
              />
            }
            label={t('results.hotels.compare')}
            className='compare-checkbox'
          />
        </Grid>

        <Hidden mdUp>
          <Grid item xs={12} className='right-side bottom'>
            {getPriceInfoMobile()}
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default HotelCard;
