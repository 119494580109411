import React, { useEffect, useState } from 'react';
import { Alert, Button, Container, Grid, Hidden, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getAutocompleteFlights } from '../../../store/services/Autocomplete';
import { filterActionFlights, getFiltersActionFlights, searchActionFlights, searchThreeDaysActionFlights } from '../../../store/actions';
import { connect } from 'react-redux';
import Preloader from '../../common/Preloader';
import { ArrowBack } from '@mui/icons-material';
import FiltersComponent from './FiltersComponent';
import ResultsComponent from './ResultsComponent';
import Multibuscador from '../../common/Multibuscador/Multibuscador';
import ThreeDaysTable from './ThreeDaysTable';
import { checkToken } from '../../../store/services/Login';
import { useClientData } from '../../../context/ClientContext';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';

const FILTERS = [
  'Stopovers Count',
  'Fare Type Count',
  'Content Type Count',
  'Airline Count',
  'Airport Count',
  'Baggage Count',
  'Price Count',
  'Schedule Departure Count'
];

function ResultsView({
  client,
  resultSearch,
  error,
  resultSearchThreeDays,
  errorThreeDays,
  filtersResult,
  filterSearch,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { clientData } = useClientData();
  const [dataForm, setDataForm] = useState();
  const [results, setResults] = useState();
  const [filters, setFilters] = useState({});
  const [hashFlow, setHashFlow] = useState();
  const [loading, setLoading] = useState(false);
  const [resultsThreeDays, setResultsThreeDays] = useState({});
  const [loadingThreeDays, setLoadingThreeDays] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [abortController, setAbortController] = useState();
  const [resultsToCompare, setResultsToCompare] = useState([]);
  const [showComparation, setShowComparation] = useState(false);
  const [allDestinations, setAllDestinations] = useState([]);
  const [activeSort, setActiveSort] = useState();
  const [lastSearch, setLastSearch] = useState();

  useEffect(() => {
    setResults({});
    setInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (loading && resultSearch) {
      if (resultSearch?.status) {
        setResults(resultSearch.data);
        setNoResults(false);
        setHashFlow(resultSearch?.data?.CacheID);

        props.getFiltersProducts(`?searchId=${resultSearch?.data?.CacheID}`, null);
      } else {
        setResults(null);
        setNoResults(true);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearch]);

  useEffect(() => {
    if (loadingThreeDays && resultSearchThreeDays && Object.keys(resultSearchThreeDays).length > 0) {
      setResultsThreeDays(resultSearchThreeDays);
      setLoadingThreeDays(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearchThreeDays]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      setNoResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (errorThreeDays) {
      setLoadingThreeDays(false);
      setResultsThreeDays(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorThreeDays]);

  useEffect(() => {
    if (filtersResult?.filters) {
      const search = new URLSearchParams(location.search);

      if (!results || results.length === 0 || search.size === 0) {
        setFilters(transformFilters(filtersResult?.filters?.result, true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersResult]);

  useEffect(() => {
    if (filterSearch && filterSearch?.data && Object.keys(filterSearch?.data).length > 0) {
      const flights = filterSearch?.data?.flights || [];
      setAbortController(null);
      setLoading(false);
      setResults({
        Fares: flights,
        RecommendationID: filterSearch?.data?.hash_flow
      });
      setNoResults(flights.length === 0);
      // setRepeatLastFilters(null);

      if (!hashFlow) {
        setHashFlow(filterSearch?.data?.hash_flow);
      }

      const filtersAux = transformFilters(filterSearch?.data?.filters?.result);
      setFilters(filtersAux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch]);

  const loadDefaultData = async (code) => {
    const response = await getAutocompleteFlights(code);
    if (response?.data && Array.isArray(response.data)) {
      const destination = response.data
        .sort((a, b) => a.category > b.category ? 1 : -1)
        .flatMap(elem => elem.data)
        .filter(elem => elem.iata === code)[0];
      return destination;
    }
  }

  const getDestinationsData = async (destinations) => {
    const destinationsPromises = destinations.map(elem => loadDefaultData(elem) || '');

    return Promise.all(destinationsPromises).then(values => {
      setAllDestinations([...new Set(values.flat())]);
      return values;
    });
  }

  // const getDestinationsData = async (origins, destinations) => {
  //   const originsPromises = origins.map(elem => loadDefaultData(elem) || '');
  //   const destinationsPromises = destinations.map(elem => loadDefaultData(elem) || '');

  //   return Promise.all([...originsPromises, ...destinationsPromises]).then(values => {
  //     setAllDestinations([...new Set(values.flat())]);
  //     const originsObj = values.slice(0, originsPromises.length);
  //     const destinationsObj = values.slice(originsPromises.length);

  //     return { origins: originsObj, destinations: destinationsObj };
  //   });
  // }

  const setInitialData = async () => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem('jwt');
      await checkToken(accessToken);

      const { tripType, origins, destinations, datesDeparture, datesReturn, adults, kids, babys, cabinClasses, threeDays } = params;

      const originsAux = origins.split(',');
      const destinationsAux = destinations.split(',');
      const datesDepartureAux = datesDeparture.split(',');
      const datesReturnAux = datesReturn !== 'NA' ? datesReturn.split(',') : null;

      if (threeDays === '1') {
        setLoadingThreeDays(true);
      }

      const currentLocation = location.pathname + location.search.slice(1);
      const search = new URLSearchParams(location.search);

      let lastSearchAux = lastSearch?.indexOf('pag=');
      let currentLocationAux = currentLocation?.indexOf('pag=');
      lastSearchAux = lastSearchAux > -1 ? lastSearch.slice(0, lastSearchAux - 1) : lastSearch;
      currentLocationAux = currentLocationAux > -1 ? currentLocation.slice(0, currentLocationAux) : currentLocation;

      const uniqueDestinations = [...new Set(originsAux.concat(destinationsAux))];

      const allDestinationsAux = search.size > 0 && search.get('searchId') && allDestinations?.length > 0
        ? allDestinations
        : await getDestinationsData(uniqueDestinations);

      const segments = originsAux.map((origin, i) => ({
        origin: allDestinationsAux.find(destination => destination.iata === origin),
        destination: allDestinationsAux.find(destination => destination.iata === destinationsAux[i]),
        dateDeparture: dayjs(datesDepartureAux[i], 'DDMMYY').format(DATE_FORMAT),
        dateArrive: datesReturnAux?.[i] ? dayjs(datesReturnAux[i], 'DDMMYY').format(DATE_FORMAT) : null
      }));

      const newDataForm = {
        segments,
        adults: parseInt(adults),
        kids: parseInt(kids),
        babys: parseInt(babys),
        currency: 'USD',
        tripType,
        cabinClasses,
        threeDays: threeDays === '1'
      };

      setDataForm(newDataForm);

      if (lastSearchAux !== currentLocationAux || search.size === 0) {
        setLastSearch(location.pathname + location.search.slice(1));
        setNoResults(false);
        setResultsToCompare([]);

        if (search.size > 0 && search.get('searchId')) {
          abortController && abortController.abort();
          props.filterProducts(location.search);
        } else {
          setActiveSort({ name: 'recommended' });

          const legs = originsAux.map((origin, i) => ({
            departureAirportCity: origin,
            arrivalAirportCity: destinationsAux[i],
            flightDate: dayjs(datesDepartureAux[i], 'DDMMYY').format(DATE_FORMAT_INTERNATIONAL)
          }));

          if (tripType === 'roundtrip') {
            legs.push({
              departureAirportCity: destinationsAux[0],
              arrivalAirportCity: originsAux[0],
              flightDate: dayjs(datesReturnAux[0], 'DDMMYY').format(DATE_FORMAT_INTERNATIONAL)
            })
          }

          const passengers = [];

          if (newDataForm.adults) {
            passengers.push({
              type: "ADT",
              subType: "",
              count: adults
            });
          }
          if (newDataForm.kids) {
            passengers.push({
              type: "CHD",
              subType: "",
              count: kids
            });
          }
          if (newDataForm.babys) {
            passengers.push({
              type: "INF",
              subType: "",
              count: babys
            });
          }

          const dataToSend = {
            legs,
            passengers,
            airlines: "NA",
            excludedAirlines: "NA",
            cabinClasses,
            market: "NA"
          };

          abortController && abortController.abort();
          const controller = new AbortController();
          setAbortController(controller);
          setHashFlow(null);
          const token = clientData?.credential?.api?.tokenFlights;
          props.searchProducts(token, dataToSend, controller.signal);

          if (threeDays === '1') {
            props.searchThreeDaysProducts(token, dataToSend, controller.signal);
          }
        }
      }
    } catch (error) {
      navigate('/login', { state: { expiredSession: true } });
    }
  }

  const getTotalPrice = (flight) => {
    return flight.FareAmount
      + flight.TaxAmount
      + flight.ExtendedFareInfo.Fee.Amount
      - flight.ExtendedFareInfo.Commission.Amount
      - flight.ExtendedFareInfo.Over.Amount
  }

  const getMinimunPrice = () => {
    if (results?.Fares) {
      const min = Math.min.apply(Math, results.Fares?.map(elem => getTotalPrice(elem)) || []);
      return min;
    }
  }

  const handleChangeSort = (data) => {
    setActiveSort(data);

    // let url = location.pathname + `?searchId=${hashFlow || hashFlowCache}`;

    // if (data.name) {
    //   url += `&sorting=${data.name}`;

    //   if (data.sort) {
    //     url += `_${data.sort}`;
    //   }
    // }

    // navigate(url);
  }

  const transformFilters = (filtersAux) => {
    const transformedFilters = {};
    FILTERS.forEach(elem => {
      let name = elem.replaceAll(' ', '');
      name = name[0].toLowerCase() + name.slice(1);
      transformedFilters[name] = getFilter(filtersAux, elem);
    })
    return transformedFilters;
  }

  const getFilter = (filtersAux , filter) => {
    return filtersAux?.find(elem => {
      const keys = Object.keys(elem);
      return keys.includes(filter);
    })?.[filter]
  }

  const handleChangeFilters = (data) => {
    if (hashFlow) {
      let url = location.pathname + `?searchId=${hashFlow}`;

      // if (activeSort?.name) {
      //   url += `&sorting=${activeSort.name}`;

      //   if (activeSort.sort) {
      //     url += `_${activeSort.sort}`;
      //   }
      // }

      if (data?.price && data?.price.length === 2) {
        url += `&price=${data?.price.join(',')}`;
      }

      if (data?.stopover && data?.stopover.length > 0) {
        url += `&stopover=${data?.stopover.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.fareType && data?.fareType.length > 0) {
        url += `&fare_type=${data?.fareType.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.contentType && data?.contentType.length > 0) {
        url += `&content_type=${data?.contentType.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.baggage && data?.baggage.length > 0) {
        url += `&baggage=${data?.baggage.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.airline && data?.airline.length > 0) {
        url += `&airline=${data?.airline.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.airport && data?.airport.length > 0) {
        url += `&airport=${data?.airport.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.schedule && data?.schedule.length > 0) {
        const departure = data?.schedule?.map(schedule => schedule.departure.map(elem => dayjs.unix(elem).format('YYYY-MM-DD_HH:mm')).join('-'));
        const arrival = data?.schedule?.map(schedule => schedule.arrival.map(elem => dayjs.unix(elem).format('YYYY-MM-DD_HH:mm')).join('-'));
        url += `&schedule_departure=${departure.join(',')}&schedule_arrival=${arrival.join(',')}`;
      }

      navigate(url);

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  return (
    <Grid className='flights-results results-page'>
      <Grid className='search-container'>
        <Container>
          <Multibuscador
            module='flights'
            defaultData={dataForm}
            isResultsView
            allDestinations={allDestinations}
          />
        </Container>
      </Grid>

      {loading && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadHotel.gif`}
          text={t('common.searchingBestPrices')}
        />
      )}

      <Container>
        {!loading && noResults ? (
          <Alert severity='error' className='alert-no-results'>
            <Typography className='title'>{t('results.flights.noFlights')}</Typography>
            <Typography className='subtitle'>{t('results.flights.otherSearch')}</Typography>
          </Alert>
        ) : (
          <>
            {(!showComparation || resultsToCompare.length === 0) ? (
              <Grid className='results-container'>
                {!loading && (
                  <Typography className='title'>
                    {t('results.flights.availableFlights', {
                      flights: results?.Fares?.length || 0,
                      origin: allDestinations.filter(elem => elem.iata === dataForm?.segments?.[0]?.origin?.iata).pop()?.label,
                      destination: allDestinations.filter(elem => elem.iata === dataForm?.segments?.[0]?.destination?.iata).pop()?.label
                    })}
                    &nbsp;{!results?.Fares || results?.Fares?.length === 0
                      ? ''
                      : t('results.flights.fromPrice', { currency: results?.Fares?.[0].Currency, price: parseInt(getMinimunPrice()) })
                    }
                  </Typography>
                )}

                <Grid container columnSpacing={3} rowSpacing={2}>
                  <Hidden lgDown>
                    <Grid item lg={3}>
                      <FiltersComponent
                        filters={filters}
                        loading={loading}
                        dataForm={dataForm}
                        currency={results?.Fares?.[0]?.Currency}
                        handleChangeFilters={handleChangeFilters}
                      />
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} lg={9}>
                    {params.threeDays === '1' && resultsThreeDays && (
                      <ThreeDaysTable results={resultsThreeDays} loading={loadingThreeDays} />
                    )}

                    <ResultsComponent
                      client={client}
                      tripType={dataForm?.tripType}
                      results={results}
                      loading={loading}
                      activeSort={activeSort}
                      resultsToCompare={resultsToCompare}
                      // providers={PROVIDERS}
                      // favorites={favorites}
                      // showDistance={dataForm?.pointInterest}
                      // destination={dataForm?.destination?.label}
                      // hashFlow={hashFlow}
                      handleChangeSort={handleChangeSort}
                      // handleClickHotel={handleClickHotel}
                      // handleClickCompareHotel={handleClickCompareHotel}
                      // handleClickFavorite={handleClickFavorite}
                    />
                  </Grid>
                </Grid>

                {/* {resultsToCompare.length > 0 && getComparatorContainer()} */}
              </Grid>
            ) : (
              <Grid className='comparation-component'>
                <Button className='button row' onClick={() => setShowComparation(false)}>
                  <ArrowBack />{t('results.hotels.backResults')}
                </Button>

                <Typography className='title'>
                  {t('results.hotels.compareHotelsInDestination', { destination: dataForm?.destination?.label })}
                </Typography>

                {/* <ComparationComponent
                  client={props.client}
                  dataForm={dataForm}
                  resultsToCompare={resultsToCompare}
                  providers={PROVIDERS}
                  hashFlow={hashFlow}
                  hotels={results}
                  handleGoBack={() => setShowComparation(false)}
                  handleClickHotel={handleClickHotel}
                  handleClickAddHotel={hotel => handleClickCompareHotel(hotel, true)}
                  handleClickRemoveHotel={hotel => handleClickCompareHotel(hotel, false)}
                /> */}
              </Grid>
            )}

            {/* {results && (
              <HotelModal
                client={props.client}
                checkin={dataForm?.checkin}
                checkout={dataForm?.checkout}
                isOpen={openModal}
                hotels={results}
                defaultView={defaultView}
                selectedHotel={selectedHotel}
                hashFlow={hashFlow}
                providers={PROVIDERS}
                pointInterest={dataForm?.pointInterest ? dataForm?.destination : null}
                handleClose={handleCloseModal}
                onHotelSelect={hotel => setSelectedHotel(hotel)}
              />
            )} */}
          </>
        )}
      </Container>
    </Grid>
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    searchProducts: (token, dataForm, signalAbort = null) => dispatch(searchActionFlights(token, dataForm, signalAbort)),
    searchThreeDaysProducts: (token, dataForm, signalAbort = null) => dispatch(searchThreeDaysActionFlights(token, dataForm, signalAbort)),
    getFiltersProducts: (query, signalAbort) => dispatch(getFiltersActionFlights(query, signalAbort)),
    filterProducts: (query, signalAbort) => dispatch(filterActionFlights(query, signalAbort))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);
