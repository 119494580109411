import React from 'react';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';

const InputWrapper = ({ children, label, error, helperText, required = false }) => {
  return (
    <FormControl fullWidth className='input-wrapper'>
      {label && <FormLabel>{label} {required && <span className='required'>*</span>}</FormLabel>}
      {children}
      {helperText &&
        <FormHelperText error={error}>{helperText}</FormHelperText>
      }
    </FormControl>
  )
}

export default InputWrapper;
