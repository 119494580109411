import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Hidden, Icon, Link, Step, StepLabel, Stepper, Typography } from '@mui/material';
import Timer, { TimerProvider } from './Timer';
import { Suspense } from 'react';
import PreloadStep from './PreloadStep';
import PurchaseDetail from './PurchaseDetail';
import SecureSite from './SecureSite';
import MessagesSidebar from './MessagesSidebar';
import TotalAPagar from './TotalAPagar';
import ContainerFixedMobile from "react-headroom";
import { connect } from 'react-redux';
import { getInfoStepAction } from '../../../store/actions';
import Preloader from '../../common/Preloader';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useClientData } from '../../../context/ClientContext';
import * as TrainsDetail from './utils/TrainsDetail';
import Preload from './Preload';
import { CheckoutProvider, useCheckoutContext } from '../../../context/CheckoutContext';
import { getPayment } from '../../../store/services/Login';
import { LoadingButton } from '@mui/lab';

const PRELOAD = {
  hotels: 'preloadHotel.gif',
  trains: 'preloadTrenes.gif'
};

const ChangePriceDialog = (props) => {
  const { t } = useTranslation();
  const [openChangePriceModal, setOpenChangePriceModal] = useState(false);

  useEffect(() => {
    if (props.dataStep?.GET_PAYMENT?.response?.step?.data?.change_price) {
      setOpenChangePriceModal(true);
    }
  }, [props.dataStep]);

  useEffect(() => {
    if (!props.dataStep?.GET_PAYMENT) {
      const changePrice = props.purchaseDetail?.priceDetail?.find((priceDetail) => (
        priceDetail?.charge?.find((elem) => elem?.fare_class?.change_price)
      )) ? true : false;
      setOpenChangePriceModal(changePrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.purchaseDetail]);

  return (
    <Dialog open={openChangePriceModal} className='change-price-modal' maxWidth='sm'>
      <ErrorOutline className='error-icon' />

      <DialogTitle>
        {t('checkout.common.attention')}
      </DialogTitle>

      <DialogContent>
        {t('checkout.common.changePriceMessage')}
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          onClick={() => setOpenChangePriceModal(false)}
        >
          {t('checkout.common.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Steps = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const componentRef = useRef();
  const { payment, currentProduct, setPayment, setClientData } = useClientData();
  const [activeStep, setActiveStep] = useState(props.activeStep || 0);
  const [data, setData] = useState(null);
  const [preload, setPreload] = useState(false);

  useEffect(() => {
    ;(async () => {
      if (activeStep === 0) {
        const accessToken = localStorage.getItem('jwt');
        const tokenCheckout = currentProduct?.config_work_unit?.api_checkout_token;;
        const dataToSend = {
          hash: props.hash,
          ip: props.ip,
          step: 'initStepCrossell',
          finishedStep: []
        };

        if (props.steps?.[activeStep]?.stepName === 'initStepPaymentV2') {
          let paymentAux = payment;
          if (!paymentAux) {
            paymentAux = await getPaymentData();
          }

          dataToSend.clientPaymentInfo = paymentAux;
        }

        props.getInfoStepAction(accessToken, tokenCheckout, dataToSend, props.steps[activeStep].action);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(props.dataStep);
    setPreload(false);
  }, [props.dataStep]);

  useEffect(() => {
    if (props.errorStep) {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      navigate(iframePath + `/checkout/${props.module}/error`, {
        replace: true,
        state: { errorTimeout:
          props.dataStep?.GET_PAYMENT?.response?.status === 504 || props.dataStep?.GET_PAYMENT?.code === 'ERR_NETWORK'
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errorStep]);

  const getPaymentData = async () => {
    try {
      const token = localStorage.getItem('jwt');
      if (token) {
        const response = await getPayment(token);
        setPayment(response || null);
        localStorage.setItem('payment', JSON.stringify(response || null));
        return response;
      }
    } catch (error) {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  const getTotalPrice = () => {
    if (props.module === 'hotels') {
      return props.purchaseDetail.totalAmount;
    } else if (props.module === 'trains') {
      return TrainsDetail.getTotalPrice(props.purchaseDetail);
    }
  }

  const handleNext = async (skipStep = false, paymentData = {}) => {
    const responseChildComponent = skipStep
      ? { data: null, status: true }
      : await componentRef?.current?.handleSubmit();

    if (responseChildComponent?.status) {
      let dataChild = JSON.parse(JSON.stringify(responseChildComponent.data));
      if (activeStep === props.steps.length - 1) {
        const isPaymentStep = props.steps?.[activeStep]?.stepName === 'initStepPaymentV2';
        let locationState = null;
        let extraData = {};

        const id = responseChildComponent.reservationId
          || data?.GET_PAYMENT?.response?.step?.data?.responseSonata?.data?.number
          || data?.GET_PAYMENT?.response?.step?.data?.responseSonata?.reservation_number;
        if (id) {
          extraData.reservation_id = id;
        }

        if (isPaymentStep) {
          const selectedPayment = data?.GET_PAYMENT?.response?.step?.data?.payments?.find(elem => elem.name === responseChildComponent.paymentSelected);
          const index = dataChild?.dataForm?.payments?.findIndex(elem => elem.paymentMethod?.name === responseChildComponent.paymentSelected);
          if (index > -1) {
            dataChild.dataForm.payments[index] = {
              ...dataChild.dataForm.payments[index],
              ...paymentData
            }
          }

          locationState = {
            pendingPayment: false,
            bookingStatus: {
              emited: true,
              payment: true
            },
            paymentData: selectedPayment
          }

          const availableCredit = payment?.config?.credit?.remaining_balance || 0;
          const totalPrice = Number(getTotalPrice());

          if (selectedPayment?.name === 'credit' && availableCredit < totalPrice) {
            locationState.pendingPayment = true;
            locationState.bookingStatus = {};
          } else if (paymentData?.status === 'REJECTED') {
            locationState.status = paymentData?.status;
          }
        }

        if (!locationState?.pendingPayment) {
          props.getFinalStep([dataChild], props.steps[activeStep].stepName, extraData, locationState);
        } else {
          const id = data?.GET_PAYMENT?.response?.step?.data?.idTransaction;
          const number = data?.GET_PAYMENT?.response?.step?.data?.responseSonata?.data?.number
            || data?.GET_PAYMENT?.response?.step?.data?.responseSonata?.reservation_number;
          const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
          const url = iframePath + `/checkout/${props.module}/booking/id/${id}/${number}`;
          navigate(url, {
            state: {
              purchaseDetail: props.purchaseDetail,
              products: props.products,
              hash: props.hash,
              dataStep: [dataChild],
              ...locationState
            },
            replace: true
          })
        }
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setPreload(true);
        setActiveStep(current => current + 1);

        const accessToken = localStorage.getItem('jwt');
        const tokenCheckout = currentProduct?.config_work_unit?.api_checkout_token;
        const dataToSend = {
          hash: props.hash,
          ip: props.ip,
          step: props.steps[activeStep].stepName,
          finishedStep: [dataChild]
        };

        if (props.steps?.[activeStep + 1]?.stepName === 'initStepPaymentV2') {
          let paymentAux = payment;
          if (!paymentAux) {
            paymentAux = await getPaymentData();
          }

          dataToSend.clientPaymentInfo = paymentAux;
        }

        if (responseChildComponent?.reservationId) {
          dataToSend.reservation_id = responseChildComponent.reservationId;
        }

        props.getInfoStepAction(accessToken, tokenCheckout, dataToSend, props.steps[activeStep + 1].action);
      }
    }

    return true;
  }

  const StepperCustom = () => {
    const maxWidth = props.steps.length > 3 ? 1170 : 300 * props.steps.length

    return (<>
      {props.steps.length > 1 &&
        <section className="stepper-container">
          <Stepper activeStep={activeStep} className="stepper-component" style={{ maxWidth: maxWidth }}>
            {props.steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.name}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </section>
      }
    </>)
  }

  const memoizedChangePriceDialog = useMemo(() => {
    return (
      <ChangePriceDialog
        dataStep={props.dataStep}
        purchaseDetail={props.purchaseDetail}
      />
    );
  }, [props.dataStep, props.purchaseDetail]);

  const getPreload = () => {
    return <>
      <Preloader
        addDots
        image={`sites/${props.client.client.name}/${PRELOAD[params.module]}`}
        text={t('common.processingRequest')}
      />
      <PreloadStep />
    </>
  }

  const StepContent = () => {
    if (data) {
      const Component = React.lazy(() => import(`./scenes/${props.steps[activeStep].nameContainer}`));

      return <Suspense fallback={getPreload()}>
        <Component
          ref={componentRef}
          data={data}
          module={props.module}
          products={props.products}
          purchaseDetail={props.purchaseDetail}
          selectedReservation={props.selectedReservation}
          preload={props.preloadStep}
          activeStep={props.steps?.[activeStep]}
          timer={props.timer}
          expired={props.expired || false}
          handleNext={handleNext}
        />
      </Suspense>
    }

    return getPreload();
  }

  const getMessages = () => {
    let arrayMessages = [];

    if (data) {
      let keysData = Object.keys(data);
      keysData.forEach(key => {
        if (data?.[key]?.response?.step?.data?.messages) {
          arrayMessages = arrayMessages.concat(data?.[key]?.response?.step?.data?.messages);
        }
      })
    }

    return arrayMessages;
  }

  const getExpediaConditions = (provider) => {
    if (provider) {
      const providerName = provider.replaceAll(' ', '').toLowerCase();
      if (providerName.includes('expedia')) {
        const url = 'https://developer.expediapartnersolutions.com/terms/es';
        return <>
          &nbsp;y de&nbsp;
          <Link href={url} target='_blank' color='#155dff'>Expedia</Link>
        </>
      }
    }

    return null;
  }

  const ContinueButton = () => {
    const { paymentSelected } = useCheckoutContext();
    const [acceptedConditions, setAcceptedConditions] = useState(activeStep === 0 ? false : true);
    const [loading, setLoading] = useState(false);

    const isPaymentStep = props.steps?.[activeStep]?.stepName === 'initStepPaymentV2';
    const isPaymentMethodSelected = isPaymentStep && paymentSelected;

    const availableCredit = payment?.config?.credit?.remaining_balance || 0;
    const totalPrice = Number(getTotalPrice());

    const textButton = !isPaymentStep || !isPaymentMethodSelected
      ? t('checkout.common.continue')
      : isPaymentMethodSelected && availableCredit < totalPrice
        ? t('checkout.common.finish')
        : t('checkout.common.pay');
    
    const onClickButton = async () => {
      setLoading(true);
      await handleNext(false);
      setLoading(false);
    }

    return <>
      <Hidden mdDown>
        <div className="text-total-sumary">
          <TotalAPagar
            purchaseDetail={props.purchaseDetail}
            products={props.products}
            module={props.module}
          />
          <FormControlLabel
            control={<Checkbox
              checked={acceptedConditions}
              onChange={(e, checked) => setAcceptedConditions(checked)}
            />}
            label={
              <Typography>
                {t('checkout.common.generalConditions')}&nbsp;
                <Link href={props.client?.client.conditions} target='_blank' color='#155dff'>
                  {t('checkout.common.general')}
                </Link>
                {getExpediaConditions(props.products?.pivot?.[0]?.cardDetail?.provider)}
              </Typography>
            }
          />
        </div>
      </Hidden>

      <LoadingButton
        className="btn-next-step"
        color="secondary"
        variant="contained"
        onClick={onClickButton}
        endIcon={<Icon>arrow_forward</Icon>}
        disabled={!acceptedConditions || (isPaymentStep && !isPaymentMethodSelected)}
        loading={loading}
      >
        {textButton}
      </LoadingButton>
    </>
  }

  return (
    <TimerProvider minutes={props.timer?.value}>
      <CheckoutProvider>
        <div className='steps'>
          <StepperCustom />

          <Hidden mdUp>
            <ContainerFixedMobile pinStart={210}>
              <PurchaseDetail
                purchaseDetail={props.purchaseDetail}
                products={props.products}
                module={props.module}
              />
            </ContainerFixedMobile>
          </Hidden>

          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} className='col-content'>
                {preload ? (
                  <>
                    <Preloader
                      addDots
                      image={`sites/${props.client.client.name}/${PRELOAD[params.module]}`}
                      text={t('common.processingRequest')}
                    />
                    <Preload />
                  </>
                ) : (<>
                  <StepContent />

                  {memoizedChangePriceDialog}
                  </>
                )}
              </Grid>

              <Hidden mdDown>
                <Grid item md={4} className="col-sidebar">
                  <Grid>
                    <Timer
                      timer={props.timer}
                      expired={props.expired || false}
                    />
                    <PurchaseDetail
                      purchaseDetail={props.purchaseDetail}
                      products={props.products}
                      module={props.module}
                    />
                  </Grid>
                  <MessagesSidebar messages={getMessages()} />
                  <SecureSite client={props.client?.client} />
                </Grid>
              </Hidden>
            </Grid>
          </Container>

          <div className="steps-buttons">
            <Container className='row'>
              <div className='row help-container'>
                {/* <Hidden lgDown>
                  <div className='icon-container'>
                    <QuestionAnswer />
                  </div>
                  <div className='message-container'>
                    <Typography>Hola,</Typography>
                    <Typography>¿Cómo podemos ayudarle?</Typography>
                  </div>
                </Hidden> */}
              </div>
              <div className="steps-buttons-inner">
                <ContinueButton />
              </div>
            </Container>
          </div>
        </div>
      </CheckoutProvider>
    </TimerProvider>
  );
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getInfoStepAction: (access, tokenCheckout, hash, ip, nextStep, currentDataStep, prevStep) =>
      dispatch(getInfoStepAction(access, tokenCheckout, hash, ip, nextStep, currentDataStep, prevStep)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
