import React from 'react';
import {Dialog, Button, DialogTitle, DialogContent, DialogActions, IconButton} from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

function DialogMessage({ open, onClose, title, message, showCloseButton, showActions }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} className='dialog-message'>
      {title &&
        <DialogTitle >
          {title}
          {showCloseButton && (
            <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          )} </DialogTitle>}
      <DialogContent>
        {message}
      </DialogContent>
      {showActions && (
        <DialogActions>
          <Button onClick={onClose}>{t('common.close')}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default DialogMessage;
