import { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Image from '../../common/Image';

const FlightScales = ({ cabinClass, option }) => {
  const { t } = useTranslation();

  const getDaysAfterMessage = (days) => {
    return days === 1
      ? t('results.flights.dayAfter')
      : t('results.flights.daysAfter', { days });
  }

  return <Grid className='flight-scales-container'>
    {option?.Segments.map((elem, i) => (
      <Fragment key={i}>
        {(elem.WaitHours || elem.WaitMinutes) && (
          <Grid className='connection-container'>
            {t('results.flights.scaleWait', {
              city: `${elem.Departure.CityName}, ${elem.Departure.CountryName} (${elem.Departure.AirportCode})`,
              hours: elem.WaitHours,
              minutes: elem.WaitMinutes
            })}
          </Grid>
        )}
        <Grid container className='scale-container' columnSpacing={2}>
          {i === 0 && (
            <>
              <Grid item xs={6} className='row' style={{ marginBottom: 10 }}>
                <b>{t('results.flights.itinerary')}</b>
              </Grid>
              <Grid item xs={6} className='text-right' style={{ marginBottom: 10 }}>
                <Typography>{t('results.flights.flightTime')}: <b>{option.OptionDurationHours}h {option.OptionDurationMinutes}m</b></Typography>
                {option.DayAfter > 0 && (
                  <Typography><span className='plus-days'>+{option.DayAfter}D</span> ({getDaysAfterMessage(option.DayAfter)})</Typography>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={6} className='row' style={{ marginBottom: 10 }}>
            <Image img={`providersFlights/${elem.Airline}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
            {elem.AirlineName}
          </Grid>
          <Grid item xs={6} className='text-right' style={{ marginBottom: 10 }}>
            <Typography>{t('results.flights.class')}: <b>{cabinClass}</b></Typography>
            <Typography>{t('results.flights.flightNumber')}: <b>{elem.FlightNumber}</b></Typography>
          </Grid>
          <Grid item xs={12} className='airports-container row'>
            <Grid className='scale-points column'>
              <div className='circle empty' />
              <div className='line'/>
              <div className='circle' />
            </Grid>
            <Grid className='airports-info column'>
              <Grid container className='row' columnSpacing={1}>
                <Grid item xs={2} className='airport-code'>{elem.Departure.AirportCode}</Grid>
                <Grid item xs={3} className='time'>{elem.Departure.Time}</Grid>
                <Grid item xs={7} className='date'>{dayjs(elem.Departure.Date).format('ddd, DD MMM YY')}</Grid>
                <Grid item xs={12} className='airport'>{elem.Departure.AirportName}</Grid>
              </Grid>
              <Grid container className='row' columnSpacing={1} style={{ marginTop: 20 }}>
                <Grid item xs={2} className='airport-code'>{elem.Arrival.AirportCode}</Grid>
                <Grid item xs={3} className='time'>{elem.Arrival.Time}</Grid>
                <Grid item xs={7} className='date'>{dayjs(elem.Arrival.Date).format('ddd, DD MMM YY')}</Grid>
                <Grid item xs={12} className='airport'>{elem.Arrival.AirportName}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    ))}
  </Grid>
}

export default FlightScales;
