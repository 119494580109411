import React  from 'react';
import { Typography, Button, Container, Box, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Check} from '@mui/icons-material';
import ZohoFormRegister from './ZohoFormRegister';
import heroBackground from '../../../assets/images/prehome/background-prehome.jpg';
import providers from '../../../assets/images/prehome/logos.jpg';
import contactUs from '../../../assets/images/prehome/contact-us.svg';
import registerForm from '../../../assets/images/prehome/background-prehome-contact.jpg';

function PreHome () {

    const benefits = [
        {
            'text': 'Equipo de atención al cliente en Español.',
            'md': 3
        },
        {
            'text': 'Tarifas comisionables.',
            'md': 3
        },
        {
            'text': 'Búsqueda, reserva y emisión 100% online.',
            'md': 3
        },
        {
            'text': 'Reserva múltiples tramos a la vez.',
            'md': 3
        },
        {
            'text': 'Accede al inventario de múltiples operadores de tren: Eurostar, Thalys, Italo, Lyria, OUIGO, Trenitalia, SNCF, DB, Renfe y más.',
            'md': 4
        },
        {
            'text': 'Forma segura de pago: Aceptamos PayPal, MasterCard, Visa, Amex y todo tipo de tarjetas de crédito internacionales.',
            'md': 4
        },
        {
            'text': 'Accede a una cuenta corriente: te brindamos la posibilidad de abrir una cuenta corriente para simplificar el proceso de venta',
            'md': 4
        }
    ];

    const handleClickButtonRegister = () => {
        const registroElement = document.getElementById('registro');
        if (registroElement) {
            registroElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Box className='hero' sx={{
                py: 5,
                backgroundImage: `url(${heroBackground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#fff'
            }}>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={7} />
                        <Grid item xs={12} md={5}>
                            <Typography variant='h1' component='h1' gutterBottom>
                                Reserva billetes y pases de tren de forma rápida, sencilla y comisionable.
                            </Typography>
                            <Typography variant='body1' paragraph sx={{color: '#212529'}}>
                                Si precisas emitir billetes y pases de tren en europa para tus pasajeros,
                                registra tu agencia de viajes y comienza a utilizar la única plataforma dónde vas a poder
                                comisionar por tus ventas y recibir asistencia 100% en español. Buscar, reservar y emitir billetes
                                para tus clientes nunca fue tan fácil.
                            </Typography>
                            <Button variant='contained' color='secondary' onClick={handleClickButtonRegister}>
                                Registra tu agencia
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{ py: 5 }} className='proveedores'>
                <Container>
                    <img src={providers} alt='Proveedores' />
                </Container>
            </Box>

            <Box className='beneficios'>
                <Container>
                <Typography variant='h2' component='h2' gutterBottom sx={{color: 'white'}}>
                    Una vez que registres tu agencia, disfruta de los siguientes beneficios
                </Typography>
                <Grid container spacing={3}>
                    {benefits.map((benefit, index) => (
                        <Grid item md={benefit.md} key={index}>
                            <List>
                                <ListItem sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <ListItemIcon sx={{ minWidth: 'unset', marginBottom: '8px' }}>
                                        <Check sx={{ color: '#fff' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={benefit.text} sx={{ textAlign: 'center' }} />
                                </ListItem>
                            </List>
                        </Grid>
                    ))}
                </Grid>
                </Container>
            </Box>

            <Box className='registro' id='registro' sx={{ backgroundImage: `url(${registerForm})` }}>
                <Container>
                    <Grid container>
                        <Grid item md={7}>
                            <ZohoFormRegister />
                        </Grid>
                    </Grid>
                    <img src={contactUs} alt='Registrate' className='img-contact' />
                </Container>
            </Box>
        </>
    );
}

export default PreHome;
