import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Grid, InputLabel, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getBanners, getPayment, getProducts, getClient, login, removeCookie, getLandings } from '../../../store/services/Login';
import { LoadingButton } from '@mui/lab';
import DialogMessage from '../../common/DialogMessage';
import RawMarkup from '../../common/RawMarkup';
import { useClientData } from '../../../context/ClientContext';
import { MODULES } from '../../../utils/modules';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const FIELDS = ['user', 'pass'];

const LoginForm = () => {
  const { t } = useTranslation();
  const { setClientData, setPayment, clientData } = useClientData();
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      setClientData(null);
      localStorage.clear();
    }
    removeCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorsAux = {};

    FIELDS.forEach(elem => {
      if (!form[elem]) {
        errorsAux[elem] = true;
      }
    })

    setErrors(errorsAux);

    if (Object.keys(errorsAux).length === 0) {
      const dataToSend = {
        ...form,
        app: 1,
        cli: 1
      }
      setLoading(true);
      login(dataToSend).then(res => {
        localStorage.setItem('jwt', res.jwt);
        localStorage.setItem('hash_user', res.user.hashuser);

        const promises = [
          getProducts(res.jwt),
          getBanners(res.jwt),
          getLandings(res.jwt),
          getPayment(res.jwt)
        ];

        Promise.all(promises).then(async ([products, banners, landings, payment]) => {
          setPayment(payment);
          localStorage.setItem('payment', JSON.stringify(payment));

          res.user.id = products.user_id;
          res.user.username = products.username;
          localStorage.setItem('user', JSON.stringify(res.user));

          const productsAux = products?.products || [];
          localStorage.setItem('products', JSON.stringify(productsAux));

          let clientData = JSON.parse(localStorage.getItem('clientData') || '{}');

          clientData.landings = landings;

          if (products.work_unit_relation[0]) {
            localStorage.setItem('workUnit', JSON.stringify(products.work_unit_relation[0]));

            const clientUrl = products.work_unit_relation[0]?.url
              || (window.location.host.includes('localhost')
                ? 'stg-hoteles-react.tije.travel'
                : window.location.host);
            if (clientUrl) {
              const clientConfig = await getClient({ client: clientUrl });
              clientData = {
                ...clientData,
                ...clientConfig
              }
            }
          }
          if (products.business_unit_relation[0]) {
            localStorage.setItem('businessUnit', JSON.stringify(products.business_unit_relation[0]));
          }

          const refTableDetails = productsAux.map(elem => elem.ref_table_detail);
          const modules = MODULES.filter(module => {
            let flag = module.refTableDetail.some(elem => refTableDetails.includes(elem));
            if (module.refTableDetail?.[0] === 'other_detail') {
              flag = flag && productsAux.find(elem => elem.short_name.toLowerCase() === module.module)
            }
            return flag;
          });

          clientData.modules = modules;

          setClientData(clientData);
          localStorage.setItem('clientData', JSON.stringify(clientData));

          if (banners?.data) {
            localStorage.setItem('banners', JSON.stringify({
              ...banners,
              time: dayjs().unix()
            }));
          }

          if (modules?.length > 0 && modules?.[0]?.path?.[0] !== '/') {
            window.location.href = modules?.[0]?.path;
          } else {
            navigate(modules?.[0]?.path || '/');
          }
        }).catch(error => {
          setShowError(error?.message || t('common.anErrorOcurred'));
        }).finally(() => {
          setLoading(false);
        })
      }).catch(error => {
        setShowError(error?.message || t('common.anErrorOcurred'));
        setLoading(false);
      });
    }
  };

  const modalMessage = `<p style='font-size:18px;'>${t('common.registerMessage')} <strong style='color:#002848;'>mayorista@gotravelres.com</strong><p>`;

  return (
    <>
      <Grid className='form'>
        <Typography className='title'>{t('common.logInTitle')}</Typography>
        <form onSubmit={handleSubmit} noValidate>
          {showError && (
            <Alert severity='error' style={{ marginBottom: 10 }}>{showError}</Alert>
          )}
          <InputLabel>{t('common.user')}</InputLabel>
          <TextField
            fullWidth
            id="user"
            name="user"
            autoComplete="user"
            placeholder={t('common.user')}
            size='small'
            value={form.user || ''}
            error={errors.user}
            disabled={loading}
            onChange={(e) => setForm(current => ({ ...current, user: e.target.value }))}
          />

          <InputLabel>{t('common.password')}</InputLabel>
          <TextField
            fullWidth
            name="pass"
            type="password"
            id="pass"
            autoComplete="current-password"
            placeholder={t('common.password')}
            size='small'
            value={form.pass || ''}
            error={errors.pass}
            disabled={loading}
            onChange={(e) => setForm(current => ({ ...current, pass: e.target.value }))}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            className='submit-button'
            loading={loading}
          >
            {t('common.logInButton')}
          </LoadingButton>
        </form>

        <Grid className='buttons-bottom row'>
          <Button variant='standard' onClick={() => navigate('/recover')}>{t('common.recoverPassword')}</Button>

          {clientData?.client?.register?.link ?
            <Button variant='standard' onClick={() => navigate('/trenes/register')}>{t('common.signIn')}</Button> :
            <Button variant='standard' onClick={handleOpenDialog}>{t('common.signIn')}</Button>
          }

        </Grid>
      </Grid>

      <DialogMessage
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={t('common.registerTitle')}
        message={<RawMarkup content={modalMessage} />}
        showCloseButton
      />
    </>
  );
}

export default LoginForm;
