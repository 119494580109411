import { Fragment } from 'react';
import { Grid, Icon, Typography } from '@mui/material';
import { Train } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Image from '../../common/Image';
import { AMENITIES } from '../../../utils/trainsData';

const TrainScales = ({ segments, fareFeatures, isModal }) => {
  const { t } = useTranslation();

  const getAmenitiesIcons = (fareFeatures) => {
    let filteredAmenities = fareFeatures?.map(elem => ({
      ...elem,
      icon: AMENITIES.find(amenity => amenity.code === elem.code)?.icon
    })) || [];

    if (filteredAmenities.length > 0) {
      return <Grid className='amenities-container'>
        {filteredAmenities?.map((amenity, i) => (
          <div className='row amenity' key={i}>
            {amenity.icon && (
              <Icon fontSize='small'>{amenity.icon}</Icon>
            )}
            <Typography>{amenity.name}</Typography>
          </div>
        ))}
      </Grid>
    }
  }

  return <Grid className='scales-container'>
    {segments?.map((elem, i) => {
      const filteredFareFeatures = fareFeatures?.filter(feature => feature.segments.includes(elem.id));
      const nameAux = elem.marketingCarrier.replaceAll(' ', '');
      let diffTime = null;
      if (i < segments.length - 1) {
        diffTime = dayjs(segments[i + 1].departure).diff(dayjs(elem.arrival), 'minutes');
        diffTime = `${Math.floor(diffTime / 60)}h ${diffTime % 60}m`;
      }
      return <Fragment key={i}>
        <Grid container className='scale-container' spacing={2}>
          <Grid item xs={isModal ? 4 : 3} className='row'>
            <Grid className='scale-points column'>
              <Train fontSize='small' className='train' />
              <div className='line'/>
              <div className='circle' />
            </Grid>
            <Grid className='time-container'>
              <Typography className='time'>{dayjs(elem.departure).format('HH:mm')}</Typography>
              <Typography className='duration'>{elem.duration}</Typography>
              <Typography className='time'>{dayjs(elem.arrival).format('HH:mm')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={true} className='info-container'>
            <Typography className='place'>{elem.origin.label}</Typography>
            <Typography className='row'>
              {elem.vehicle.type}&nbsp;
              {elem.vehicle.reference}&nbsp;
              <Image img={`providersTrains/${nameAux}.png`} alt='logo-provider' className='train-logo' hideBrokenImage />
            </Typography>
            {getAmenitiesIcons(filteredFareFeatures)}
            <Typography className='place'>{elem.destination.label}</Typography>
          </Grid>
        </Grid>
        {diffTime && (
          <Grid className='connection-container'>
            {diffTime} {t('results.trains.transferTime').toLowerCase()}
          </Grid>
        )}
      </Fragment>
    })}
  </Grid>
}

export default TrainScales;
