import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Grid, Typography, Box, Icon, List, ListItem, Tooltip, Collapse, ClickAwayListener,  Hidden, Drawer, Popper, Fade, Badge,  } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrenciesAction, getReservationAction, getReservationsAction, resetReservationAction } from '../../store/actions';
import dayjs from 'dayjs';
import { AccountCircle, AddCircle, Cancel, Category, KeyboardArrowDown } from '@mui/icons-material';
import IconCustom from '../common/IconCustom';
import { useClientData } from '../../context/ClientContext';
import { useBannersData } from '../../context/BannersContext';
import Image from '../common/Image';
import { useTranslation } from 'react-i18next';

const MenuList = ({ user, menuData, handleClickLogIn }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSubmenu, setCurrentSubmenu] = useState([]);

    const handleOpenMenu = (event, submenu) => {
      setAnchorEl(event.currentTarget);
      setCurrentSubmenu(submenu);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
      setCurrentSubmenu([]);
    };

    return (
      <List className='menu-list'>
        {menuData.map((menuItem, index) => (
          <ListItem key={index} disablePadding>
            {(!menuItem.submenu || menuItem.submenu.length === 0) ? (
              <Button color="inherit" href={menuItem.link}>
                {menuItem.clave}
              </Button>
            ) : (
              <>
                <Button color="inherit" onClick={(event) => handleOpenMenu(event, menuItem.submenu)} sx={{ whiteSpace: 'nowrap' }}>
                  {menuItem.clave}
                  {menuItem.submenu && menuItem.submenu.length > 0 && <KeyboardArrowDown />}
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                  {currentSubmenu.map((submenuItem, subIndex) => (
                    <MenuItem key={subIndex} href={submenuItem.link} onClick={handleCloseMenu}>
                      {submenuItem.clave}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}

          </ListItem>
        ))}
        {!user && (
            <ListItem disablePadding>
                <Button
                    aria-label="account of current user"
                    aria-haspopup="true"
                    onClick={handleClickLogIn}
                    startIcon={<Icon>account_circle</Icon>}
                    color="default"
                    variant="outlined"
                >
                    {t('common.logIn')}
                </Button>
            </ListItem>
        )}
      </List>
    );
};

const MobileMenuList = ({ user, menuData, handleClickLogIn }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSubmenu, setCurrentSubmenu] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleOpenMenu = (event, submenu) => {
        setAnchorEl(event.currentTarget);
        setCurrentSubmenu(submenu);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentSubmenu([]);
    };

    const toggleMainMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <IconButton onClick={toggleMainMenu} color="inherit">
                {menuOpen ? <Icon>close</Icon> :<Icon>menu</Icon>}
            </IconButton>

                <Drawer
                variant="temporary"
                open={menuOpen}
                onClose={toggleMainMenu}
            >
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.8)', zIndex: 10 }}>
                        <List className='mobile-menu-list'>
                            {menuData.map((menuItem, index) => (
                                <ListItem key={index} disablePadding>
                                    {(!menuItem.submenu || menuItem.submenu.length === 0) ? (
                                        <Button color="inherit" href={menuItem.link} sx={{ fontSize: '1.5rem', margin: '1rem 0' }}>
                                            {menuItem.clave}
                                        </Button>
                                    ) : (
                                        <>
                                            <Button color="inherit" onClick={(event) => handleOpenMenu(event, menuItem.submenu)} sx={{ fontSize: '1.5rem', margin: '1rem 0' }}>
                                                {menuItem.clave}
                                                {menuItem.submenu && menuItem.submenu.length > 0 && <KeyboardArrowDown />}
                                            </Button>
                                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                                {currentSubmenu.map((submenuItem, subIndex) => (
                                                    <MenuItem key={subIndex} href={submenuItem.link} onClick={handleCloseMenu}>
                                                        {submenuItem.clave}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    )}
                                </ListItem>
                            ))}
                            {!user && (
                                <ListItem disablePadding>
                                    <Button
                                        aria-label="account of current user"
                                        aria-haspopup="true"
                                        onClick={handleClickLogIn}
                                        startIcon={<Icon>account_circle</Icon>}
                                        color="inherit"
                                        variant="outlined"
                                        sx={{ fontSize: '1.5rem', margin: '1rem 0' }}
                                    >
                                        {t('common.logIn')}
                                    </Button>
                                </ListItem>
                            )}
                        </List>
                    </div>
            </Drawer>

        </>
    );
};

const DekstopAppBarTop = (props) => {
    const { t } = useTranslation();
    const params = useParams();

    const getReservations = () => {
        return props.reservations.map(reservation => {
            const isSelected = reservation.number === props.selectedReservation?.number;
            const products = {};
            reservation?.items?.forEach(item => {
                products[item.product.ref_table_detail] = products[item.product.ref_table_detail] ? products[item.product.ref_table_detail] + 1 : 1;
            });
            reservation.products = products;

            return <Grid className={`reservation ${isSelected ? 'selected' : ''}`} key={reservation.id}>
                <div className='row reservation-title' style={{ justifyContent: 'space-between' }}>
                    <Typography className='reservation-number'>{t('common.reservation')} {reservation.number}</Typography>
                    <Typography className='status'>{t('common.open')}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <Typography className='title'>{t('common.products')}:</Typography>
                    <div>
                        {Object.entries(products).map(([key, value], i) => (
                            <Badge
                                badgeContent={value > 1 ? value : 0}
                                overlap='circular'
                                key={i}
                            >
                                {props.getIcon(key)}
                            </Badge>
                        ))}
                    </div>
                </div>
                <div className='row'>
                    <Typography className='title'>{t('common.passenger')}:</Typography>
                    <Typography>{reservation.passenger}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <div className='row'>
                        <Typography className='title'>{t('common.date')}:</Typography>
                        <Typography>{reservation.date_in ? dayjs(reservation.date_in).format('DD-MM-YYYY') : ''}</Typography>
                    </div>
                    {isSelected ? (
                        <Button
                            className='cancel-button'
                            variant='outlined'
                            size='small'
                            color='error'
                            onClick={() => props.handleSelectReservation()}
                        >
                            {t('common.inProgress')} <Cancel fontSize='small' />
                        </Button>
                    ) : (
                        <Button
                            className='continue-button'
                            variant='contained'
                            size='small'
                            onClick={() => props.handleSelectReservation(reservation)}
                        >
                            <AddCircle fontSize='small' /> {t('common.continueBooking')}
                        </Button>
                    )}
                </div>
            </Grid>
        });
    }

    const getCurrency = () => {
        const currency = props.currentProduct?.config?.CURRENCY_TO || props.currentProduct?.config?.currency_to;
        return currency || 'USD';
    }

    const formatPrice = (price) => {
        return price ? price.toFixed(2) : 0;
    }

    const getFavoritesIcon = () => {
        if (props?.modules && Array.isArray(props?.modules) && props?.modules?.find(elem => elem.module === 'hotels')) {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            return <Link to={iframePath + '/hoteles/favoritos'} color='inherit'>
                <Button
                    startIcon={<Icon className='icon-favorite'>favorite</Icon>}
                    sx={{ marginLeft: '8px' }}
                >
                    {t('common.favorites')}
                </Button>
            </Link>
        }
    }

    return (
        <AppBar position="static" className="site-header-logged">
            <Toolbar>
                <Typography variant="body1" color="inherit" component="span" sx={{ flexGrow: 1 }}>
                    {props.getCurrencies && props.getCurrencies() && (
                        <>
                            <strong>{t('common.exchangeTypeTtle')}:</strong> {props.getCurrencies()}

                            <Tooltip title={t('common.exchangeTypeDescription')}>
                                <IconButton color="inherit" size="small">
                                    <Icon>info</Icon>
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Typography>

                <Typography className='row' style={{ fontSize: 13 }}>
                    <AccountCircle />&nbsp;
                    {t('common.hello')},&nbsp;<strong>{props.user.name} {props.user.surname}</strong>
                </Typography>

                {Boolean(props.payment?.config?.credit?.remaining_balance && props.payment?.config?.credit?.remaining_balance > 0) && (
                    <Typography className='row' style={{ fontSize: 13 }}>
                        &nbsp;|&nbsp;{t('common.availableCredit')}: {getCurrency()} {formatPrice(props.payment?.config?.credit?.remaining_balance)}
                    </Typography>
                )}

                {getFavoritesIcon()}

                {props?.header?.showInfoTrenes &&
                    <Button
                        href='/trenes/info'
                        target='_blank'
                        startIcon={<Icon>train</Icon>}
                        color="secondary"
                        variant='contained'
                        sx={{ marginLeft: '16px' }}
                    >
                        Info Trenes
                    </Button>
                }

                <Button
                    startIcon={<Icon>receipt</Icon>}
                    color="default"
                    variant='contained'
                    sx={{ marginLeft: '16px' }}
                    onClick={props.handleOpenReservations}
                >
                    {t('common.myBookings')} {props.selectedReservation ? `(${props.selectedReservation.number})` : ''}
                </Button>

                <Popper open={props.openReservations} anchorEl={props.anchorEl} placement='bottom-end' transition style={{ zIndex: 20 }}>
                    {({ TransitionProps }) => (
                        <ClickAwayListener onClickAway={props.handleCloseReservations}>
                            <Fade {...TransitionProps} timeout={500}>
                                <Grid className='reservation-popper'>
                                    {getReservations()}
                                    <Button
                                        variant='outlined'
                                        className='more-button'
                                        fullWidth
                                        onClick={props.handleClickMoreReservations}
                                    >
                                        {t('common.seeMoreBookings')}
                                    </Button>
                                </Grid>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>

                <Button
                    startIcon={<Icon>logout</Icon>}
                    color="default"
                    variant="outlined"
                    sx={{ marginLeft: '16px' }}
                    onClick={props.handleClickLogIn}
                >
                    {t('common.logOut')}
                </Button>
            </Toolbar>
        </AppBar>
    )
}

const MobileAppBarTop = (props) => {
    const { t } = useTranslation();
    const params = useParams();
    const [open, setOpen] = useState(false);

    const handleToggle = () => setOpen(!open);

    const handleClose = () =>  setOpen(false);

    const getReservations = () => {
        return props.reservations.map(reservation => {
            const isSelected = reservation.number === props.selectedReservation?.number;
            const products = {};
            reservation?.items?.forEach(item => {
                products[item.product.ref_table_detail] = products[item.product.ref_table_detail] ? products[item.product.ref_table_detail] + 1 : 1;
            });
            reservation.products = products;

            return <Grid className={`reservation ${isSelected ? 'selected' : ''}`} key={reservation.id}>
                <div className='row reservation-title' style={{ justifyContent: 'space-between' }}>
                    <Typography className='reservation-number'>{t('common.reservation')} {reservation.number}</Typography>
                    <Typography className='status'>{t('common.open')}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <Typography className='title'>{t('common.products')}:</Typography>
                    <div>
                        {Object.entries(products).map(([key, value], i) => (
                            <Badge
                                badgeContent={value > 1 ? value : 0}
                                overlap='circular'
                                key={i}
                            >
                                {props.getIcon(key)}
                            </Badge>
                        ))}
                    </div>
                </div>
                <div className='row'>
                    <Typography className='title'>{t('common.passenger')}:</Typography>
                    <Typography>{reservation.passenger}</Typography>
                </div>
                <div className='row' style={{ justifyContent: 'space-between' }}>
                    <Typography className='title'>{t('common.date')}:</Typography>
                    <Typography>{reservation.date_in ? dayjs(reservation.date_in).format('DD-MM-YYYY') : ''}</Typography>
                </div>

                {isSelected ? (
                    <Button
                        className='cancel-button'
                        variant='outlined'
                        size='small'
                        color='error'
                        onClick={() => props.handleSelectReservation()}
                    >
                        {t('common.inProgress')} <Cancel fontSize='small' />
                    </Button>
                ) : (
                    <Button
                        className='continue-button'
                        variant='contained'
                        size='small'
                        onClick={() => props.handleSelectReservation(reservation)}
                    >
                        <AddCircle fontSize='small' /> {t('common.continueBooking')}
                    </Button>
                )}
            </Grid>
        });
    }

    const getFavoritesIcon = () => {
        if (props?.modules && Array.isArray(props?.modules) && props?.modules?.find(elem => elem.module === 'hotels')) {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            return <Link to={iframePath + '/hoteles/favoritos'} color="inherit">
                <IconButton>
                    <Icon className='icon-favorite'>favorite</Icon>
                </IconButton>
            </Link>
        }
    }

    return (
        <AppBar position="static" className="site-header-logged">
            <Toolbar>
                <Grid container spacing={2} alignItems="center">
                    {props.getCurrencies && props.getCurrencies() && (
                        <Grid item xs={12}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <>
                                    <Typography variant="body1" color="inherit" component="span">
                                        <strong>{t('common.exchangeTypeTtle')}:</strong> {props.getCurrencies()}
                                        <IconButton color="inherit" size="small" onClick={handleToggle}>
                                            <Icon>expand_more</Icon>
                                        </IconButton>
                                    </Typography>

                                    <Collapse in={open}>{t('common.exchangeTypeDescription')}</Collapse>
                                </>
                            </ClickAwayListener>
                        </Grid>
                    )}

                    <Grid item xs={7} alignItems="center" display="flex" justifyContent="flex-start">
                        <Typography variant="body1" color="inherit" component="span">
                            {t('common.hello')},&nbsp;<strong>{props.user.name} {props.user.surname}</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={5} display="flex" justifyContent="flex-end">
                        {getFavoritesIcon()}
                        <IconButton color="inherit" onClick={props.handleOpenReservations}>
                            <Icon>receipt</Icon>
                        </IconButton>
                        <IconButton color="inherit" onClick={props.handleClickLogIn}>
                            <Icon>logout</Icon>
                        </IconButton>
                    </Grid>

                    <Popper open={props.openReservations} anchorEl={props.anchorEl} placement='bottom-start' transition style={{ zIndex: 20 }}>
                        {({ TransitionProps }) => (
                            <ClickAwayListener onClickAway={props.handleCloseReservations}>
                                <Fade {...TransitionProps} timeout={500}>
                                    <Grid className='reservation-popper'>
                                        {getReservations()}
                                        <Button variant='outlined' className='more-button' fullWidth>{t('common.seeMoreBookings')}</Button>
                                    </Grid>
                                </Fade>
                            </ClickAwayListener>
                        )}
                    </Popper>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const AppBarTop = ({
    user,
    reservations,
    client,
    payment,
    currentProduct,
    modules,
    selectedReservation,
    getCurrencies,
    handleClickLogIn,
    handleSelectReservation
}) => {
    const [anchorEl, setAnchorEl] = useState();
    const [openReservations, setOpenReservation] = useState(false);

    const handleOpenReservations = (event) => {
        if (!anchorEl) {
            setOpenReservation(true);
            setAnchorEl(event.currentTarget);
        }
    }

    const handleCloseReservations = () => {
        setOpenReservation(false);
        setAnchorEl(null);
    }

    const getIcon = (product) => {
        if (modules && Array.isArray(modules)) {
            let item = '';

            if (product === 'hotel_detail') {
                item = modules?.find(elem => elem.module === 'hotels');
            } else if (product === 'car_detail') {
                item = modules?.find(elem => elem.module === 'renting_carz');
            } else if (product === 'air_detail') {
                item = modules?.find(elem => elem.module === 'flights');
            } else if (product === 'assistance_detail') {
                item = modules?.find(elem => elem.module === 'assists');
            } else if (product.includes('train')) {
                item = modules?.find(elem => elem.module === 'trains');
            } else if (product === 'transfer_detail') {
                item = modules?.find(elem => elem.module === 'transfers');
            } else if (product === 'other_detail') {
                return <Category />;
            }

            return item
                ? <IconCustom icon={item.icon} alt={item?.moduleName} className="custom-icon" />
                : null;
        }
    }

    const handleClickMoreReservations = () => {
        // const businessUnitId = JSON.parse(localStorage.getItem('user') || '{}')?.business_unit_id;
        let adminUrl = 'https://admin-b2.e2etravelsolutions.com/#/reservation';
        const token = localStorage.getItem('jwt');
        if (token) {
            adminUrl += `?token=${token}`;
        }
        window.open(adminUrl);
        // if (businessUnitId) {
        //     let adminUrl = 'https://admin-b2.e2etravelsolutions.com/#/reservation';
        //     // let adminUrl = 'https://admin-b2.e2etravelsolutions.com/#/reservation?';  // PROD
        //     // let adminUrl = 'https://dev-b2-admin.dev001.api-services-group.com/#/reservation?';  // DEV
        //     const params = {
        //         displayedFilters: {
        //             business_unit_id__in: true,
        //             status__in: true
        //         },
        //         filter: {
        //             business_unit_id__in: [businessUnitId],
        //             status__in: ['OPEN']
        //         },
        //         order: 'DESC',
        //         page: '1',
        //         perPage: '10',
        //         sort: 'id',
        //     };
        //     adminUrl += Object.entries(params).map(([key, value]) => {
        //         const valueAux = typeof value === 'object' ? JSON.stringify(value) : value;
        //         return `${key}=${encodeURIComponent(valueAux)}`;
        //     }).join('&');
        //     window.open(adminUrl, '__blank');
        // }
    }

    return (
        <>
            <Hidden smDown>
                <DekstopAppBarTop
                    user={user}
                    payment={payment}
                    currentProduct={currentProduct}
                    reservations={reservations}
                    modules={modules}
                    selectedReservation={selectedReservation}
                    anchorEl={anchorEl}
                    openReservations={openReservations}
                    handleClickLogIn={handleClickLogIn}
                    getIcon={getIcon}
                    getCurrencies={client.header.showCurrencies && getCurrencies}
                    handleSelectReservation={handleSelectReservation}
                    handleOpenReservations={handleOpenReservations}
                    handleCloseReservations={handleCloseReservations}
                    handleClickMoreReservations={handleClickMoreReservations}
                />
            </Hidden>
            <Hidden smUp>
                <MobileAppBarTop
                    user={user}
                    reservations={reservations}
                    modules={modules}
                    selectedReservation={selectedReservation}
                    anchorEl={anchorEl}
                    openReservations={openReservations}
                    handleClickLogIn={handleClickLogIn}
                    getIcon={getIcon}
                    getCurrencies={client.header.showCurrencies && getCurrencies}
                    handleSelectReservation={handleSelectReservation}
                    handleOpenReservations={handleOpenReservations}
                    handleCloseReservations={handleCloseReservations}
                    handleClickMoreReservations={handleClickMoreReservations}
                />
            </Hidden>
        </>
    )
}

function Header({
    user,
    client,
    reservations,
    selectedReservation,
    isIframe,
    setReservations,
    handleSelectReservation,
    responseReservations,
    responseReservation,
    responseCurrencies,
    ...props
}) {
    const navigate = useNavigate();
    const params = useParams();
    const { payment, currentProduct, setClientData } = useClientData();
    const { bannersData } = useBannersData();
    const [abortControllerReservation, setAbortControllerReservation] = useState();

    useEffect(() => {
        if (user) {
            const accessToken = localStorage.getItem('jwt');
            const businessUnitId = JSON.parse(localStorage.getItem('businessUnit') || '{}')?.id;
            const selectedReservationAux = localStorage.getItem('selectedReservation');
            handleSelectReservation(selectedReservationAux ? JSON.parse(selectedReservationAux) : null);

            if (accessToken && businessUnitId) {
                const query = `?skip=0&limit=3&order_by=-id&status__in=OPEN&business_unit_id__in=${businessUnitId}`;
                props.getReservations(accessToken, query);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setReservations(responseReservations || []);

        if (responseReservations !== null && selectedReservation) {
            if (!responseReservations.find(elem => elem.number === selectedReservation?.number)) {
                handleSelectReservation(null);
                localStorage.removeItem('selectedReservation');
            } else {
                const accessToken = localStorage.getItem('jwt');
                if (accessToken) {
                    abortControllerReservation && abortControllerReservation.abort();
                    const controller = new AbortController();
                    setAbortControllerReservation(controller);
                    props.getReservation(accessToken, selectedReservation.id, controller.signal);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseReservations]);

    useEffect(() => {
        if (responseReservation !== null) {
            if (Object.keys(responseReservation).length > 0) {
               localStorage.setItem('selectedReservation', JSON.stringify(responseReservation));
                handleSelectReservation(responseReservation);
            } else {
                localStorage.removeItem('selectedReservation');
                handleSelectReservation(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseReservation]);

    const handleClickLogIn = () => {
        setClientData(null);
        localStorage.clear();
        navigate('/login');
    }

    const handleSelectReservationAux = (reservation) => {
        handleSelectReservation(reservation);
        if (reservation?.id) {
            const accessToken = localStorage.getItem('jwt');
            if (accessToken) {
                abortControllerReservation && abortControllerReservation.abort();
                const controller = new AbortController();
                setAbortControllerReservation(controller);
                localStorage.setItem('selectedReservation', reservation);
                props.getReservation(accessToken, reservation.id, controller.signal);
            }
        } else {
            props.resetReservation();
            localStorage.removeItem('selectedReservation');
            abortControllerReservation && abortControllerReservation.abort();
        }
    }

    const getCurrencies = () => {
        const filteredCurrencies = currentProduct?.currencies?.filter(elem => elem.currency_to === 'ARS') || [];
        if (filteredCurrencies.length > 0) {
            const sortedCurrencies = filteredCurrencies.sort((a, b) => a.currency === 'USD' ? -1 : 1);
            const currenciesText = sortedCurrencies.map(elem => (
                `${elem.currency} 1 = ${elem.changeAmount.replace(` ${elem.currency_to}`, '')}`
            ));
            return `Terrestre ${currenciesText.join(' ')}`;
        }

        return '';
    }

    const getLogo = () => {
        const businessUnit = JSON.parse(localStorage.getItem('businessUnit') || '{}');
        const workUnit = JSON.parse(localStorage.getItem('workUnit') || '{}');
        const bannerLogo = bannersData?.data?.modules?.['otros']?.flows?.header?.banners?.Logo?.slice(-1)?.[0]?.image_url;
        const logo = bannerLogo || businessUnit?.logo?.src || workUnit?.logo?.src;
        if (logo) {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            const link = iframePath + (client?.modules?.[0]?.path || '/');
            return <Link
                to={link}
                onClick={() => handleSelectReservationAux(null)}
            >
                <Image img={logo} alt='logo-cliente' isFullPath />
            </Link>
        }
    }

    return (
        <div className={`header-${client.client.name}`}>
            {user && !isIframe && (
                <AppBarTop
                    user={user}
                    client={client}
                    payment={payment}
                    currentProduct={currentProduct}
                    modules={client?.modules}
                    reservations={reservations}
                    selectedReservation={selectedReservation}
                    getCurrencies={getCurrencies}
                    handleClickLogIn={handleClickLogIn}
                    handleSelectReservation={handleSelectReservationAux}
                />
            )}

            <AppBar position="static" className="site-header">
                <Toolbar>
                    {getLogo()}
                    <Box sx={{ flexGrow: 1 }}></Box>

                    <Hidden smDown>
                        <MenuList
                            user={user}
                            menuData={client.header.menu}
                            handleClickLogIn={handleClickLogIn}
                        />
                    </Hidden>

                    <Hidden smUp>
                        <MobileMenuList
                            user={user}
                            menuData={client.header.menu}
                            handleClickLogIn={handleClickLogIn}
                        />
                    </Hidden>
                </Toolbar>
            </AppBar>
        </div>
    );
}

const mapStateToProps = reducers => {
    return reducers.reservationReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        getReservations: (access, query) => dispatch(getReservationsAction(access, query)),
        resetReservation: () => dispatch(resetReservationAction()),
        getReservation: (access, reservationId, signalAbort) => dispatch(getReservationAction(access, reservationId, signalAbort)),
        getCurrencies: (access, productToken) => dispatch(getCurrenciesAction(access, productToken))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
