import { createContext, useContext, useState } from 'react';

const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  const [paymentSelected, setPaymentSelected] = useState(null);

  return (
    <CheckoutContext.Provider value={{ paymentSelected, setPaymentSelected }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  return useContext(CheckoutContext);
};
