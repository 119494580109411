import { combineReducers } from "redux";
import gimmonixReducer from "./GimmonixReducer";
import trainsReducer from "./TrainsReducer";
import flightsReducer from "./FlightsReducer";
import checkoutReducer from "./CheckoutReducer";
import reservationReducer from "./ReservationReducer";

export default combineReducers({
  gimmonixReducer,
  trainsReducer,
  flightsReducer,
  checkoutReducer,
  reservationReducer
});
