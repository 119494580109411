import React, { useEffect, useState } from 'react';
import { Alert, Container, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { searchActionTrains } from '../../../store/actions';
import ResultsComponentTickets from './ResultsComponentTickets';
import ResultsComponentPasses from './ResultsComponentPasses';
import { useTranslation } from 'react-i18next';
import Preloader from '../../common/Preloader';
import { getAutocompleteTrain } from '../../../store/services/Autocomplete';
import { checkToken } from '../../../store/services/Login';
import { useClientData } from '../../../context/ClientContext';
import Multibuscador from '../../common/Multibuscador/Multibuscador';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';

function ResultsView({ client, resultSearch, error, ...props }) {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { language, updateProductToken, setClientData } = useClientData();
  const [ticketType, setTicketType] = useState();
  const [dataForm, setDataForm] = useState();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [destinationPasses, setDestinationPasses] = useState();
  const [abortController, setAbortController] = useState();
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    ;(async () => {
      try {
        const ticketTypeAux = params.ticketType;
        setLoading(true);
        setTicketType(ticketTypeAux);

        const accessToken = localStorage.getItem('jwt');
        await checkToken(accessToken);

        setResults({});

        let currencyTo = 'USD';
        if (ticketTypeAux === 'tickets') {
          currencyTo = await updateProductToken(['train_detail_segment']);
          setInitialDataTickets(currencyTo);
        } else if (ticketTypeAux === 'passes') {
          currencyTo = await updateProductToken(['train_detail_passes']);
          setInitialDataPasses(currencyTo);
        }
      } catch (error) {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (loading && (resultSearch?.offers || resultSearch?.products)) {
      const isPasses = ticketType === 'passes';

      let resultsAux = resultSearch;
      if (!isPasses) {
        const legs = {};
        resultsAux.search.legs.forEach((elem, i) => {
          const leg = `${elem.origin.code}/${elem.destination.code}/${elem.departure}:00/${i}`;
          if (Object.keys(resultsAux?.offers?.trains?.legs?.[leg] || {}).length > 0) {
            legs[leg] = resultsAux.offers.trains.legs[leg];
          } else {
            legs[leg] = {
              offers: {},
              leg: {
                departure: elem.departure,
                origin: destinations.find((destination) => destination.code === elem.origin.code),
                destination: destinations.find((destination) => destination.code === elem.destination.code)
              }
            };
          }
        });
        resultsAux.offers.trains.legs = legs;
      }

      setResults(resultsAux);
      setNoResults(isPasses
        ? resultsAux?.products?.length === 0
        : Object.values(resultsAux?.offers?.trains?.legs || {}).flatMap(elem => Object.keys(elem.offers))?.length === 0
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearch]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      setNoResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const loadDefaultData = async (code) => {
    const codeAux = code.split('__').slice(1).join('__');
    const response = await getAutocompleteTrain(codeAux, language);
    if (response && Array.isArray(response)) {
      const destination = response.find(elem => elem.code === codeAux);
      return destination;
    }
  }

  const setInitialDataTickets = async (currency) => {
    const [directTrain, roundTrip, origins, destinations, dates, times, passengers] = params['*']?.split('/');

    const originsAux = origins.split(',');
    const destinationsAux = destinations.split(',');
    const datesAux = dates.split(',');
    const timesAux = times.split(',');
    const passengersAux = passengers.split('-').map(elem => parseInt(elem));

    const originsPromises = originsAux.map(elem => loadDefaultData(elem) || '');
    const destinationsPromises = destinationsAux.map(elem => loadDefaultData(elem) || '');

    Promise.all([...originsPromises, ...destinationsPromises]).then(values => {
      setDestinations([...new Set(values)]);
      const originsObj = values.slice(0, originsPromises.length);
      const destinationsObj = values.slice(originsPromises.length);

      const tripType = [];
      if (directTrain === '1') {
        tripType.push('directTrain');
      }
      if (roundTrip === '1') {
        tripType.push('roundTrip');
      }

      const segments = Array.from(Array(originsAux.length).keys()).map(index => {
        const [typeOrigin, codeOrigin] = originsAux[index].replace('__', ' ').split(' ');
        const [typeDestination, codeDestination] = destinationsAux[index].replace('__', ' ').split(' ');
        return {
          origin: { type: typeOrigin, code: codeOrigin, continent: originsObj?.[index]?.timezone },
          destination: { type: typeDestination, code: codeDestination, continent: destinationsObj?.[index]?.timezone },
          dateDeparture: datesAux[index] ? dayjs(datesAux[index], DATE_FORMAT_INTERNATIONAL).format(DATE_FORMAT) : '',
          timeDeparture: timesAux[index] ? [timesAux[index].slice(0, 2), ':', timesAux[index].slice(2)].join('') : ''
        }
      });

      let segmentsDataForm = [];

      if (roundTrip === '1') {
        const [typeOrigin, codeOrigin] = originsAux[0].replace('__', ' ').split(' ');
        const [typeDestination, codeDestination] = destinationsAux[0].replace('__', ' ').split(' ');
        segmentsDataForm.push({
          origin: { type: typeOrigin, code: codeOrigin, continent: originsObj?.[0]?.timezone },
          destination: { type: typeDestination, code: codeDestination, continent: destinationsObj?.[0]?.timezone },
          dateDeparture: datesAux[0] ? dayjs(datesAux[0], DATE_FORMAT_INTERNATIONAL).format(DATE_FORMAT) : '',
          timeDeparture: timesAux[0] ? [timesAux[0].slice(0, 2), ':', timesAux[0].slice(2)].join('') : '',
          dateArrive: datesAux[1] ? dayjs(datesAux[1], DATE_FORMAT_INTERNATIONAL).format(DATE_FORMAT) : '',
          timeArrive: timesAux[1] ? [timesAux[1].slice(0, 2), ':', timesAux[1].slice(2)].join('') : ''
        });
      } else {
        segmentsDataForm = segments;
      }

      const minorAges = passengersAux.filter(elem => elem <= 29);
      const newDataForm = {
        segments: segmentsDataForm,
        adults: passengersAux.filter(elem => elem > 29 && elem < 60).length,
        seniors: passengersAux.filter(elem => elem >= 60).length,
        minors: minorAges.length,
        minorAges: minorAges,
        ticketType: 'tickets',
        tripType
      };

      setDataForm(newDataForm);

      const accessToken = localStorage.getItem('jwt');
      const dataToSend = {
        business: {
          token: '9b1b09003022da73d9aa35661afdce4ea640ca6f299d05675b32bbf785775c6f'
        },
        type: 'point_to_point',
        type_leg: roundTrip === '1' ? 'roundtrip' : 'one_way',
        modules: ['train'],
        travelers: passengers.split('-').map(elem => ({ age: elem })),
        legs: segments.map(elem => ({
          origin: elem.origin,
          destination: elem.destination,
          departure: `${dayjs(elem.dateDeparture, DATE_FORMAT).format(DATE_FORMAT_INTERNATIONAL)}T${elem.timeDeparture}`,
          directOnly: directTrain === '1'
        })),
        currency
      };

      abortController && abortController.abort();
      const controller = new AbortController();
      setAbortController(controller);
      props.searchProducts(accessToken, dataToSend, controller.signal);
    });
  }

  const setInitialDataPasses = (currency) => {
    const [destination, date, time, passengers] = params['*']?.split('/');

    const passengersAux = passengers.split('-').map(elem => parseInt(elem));
    const minorAges = passengersAux.filter(elem => elem <= 27);
    const newDataForm = {
      segments: [{
        origin: '',
        destination,
        dateDeparture: dayjs(date).format(DATE_FORMAT),
        timeDeparture: time ? [time.slice(0, 2), ':', time.slice(2)].join('') : '',
        dateArrive: '',
        timeArrive: ''
      }],
      adults: passengersAux.filter(elem => elem > 27 && elem < 60).length,
      seniors: passengersAux.filter(elem => elem >= 60).length,
      minors: minorAges.length,
      minorAges: minorAges,
      ticketType: 'passes',
      tripType: []
    };

    setDataForm(newDataForm);

    const accessToken = localStorage.getItem('jwt');
    const dataToSend = {
      business: {
        token: 'cyurKWQXP4LTnPLbWUQdASd5cqaZRXC7RNkq3qa2'
      },
      travelers: passengers.split('-').map(elem => ({ age: elem })),
      validityStartDate: date,
      place: { code: destination },
      currency
    };

    abortController && abortController.abort();
    const controller = new AbortController();
    setAbortController(controller);
    props.searchProducts(accessToken, dataToSend, controller.signal);
  }

  return (
    <Grid className='trains-results results-page'>
      <Grid className='search-container'>
        <Container>
          <Multibuscador
            module='trains'
            defaultData={dataForm}
            isResultsView
            setDefaultDestinationPasses={setDestinationPasses}
          />
        </Container>
      </Grid>

      {loading && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadTrenes.gif`}
          text={t('common.searchingBestPrices')}
        />
      )}

      <Container>
        {!loading && noResults ? (
          <Alert severity='error' className='alert-no-results'>
            <Typography className='title'>{t('results.trains.noTrains')}</Typography>
            <Typography className='subtitle'>{t('results.trains.otherSearch')}</Typography>
          </Alert>
        ) : (
          <>
            {ticketType === 'tickets' && (
              <ResultsComponentTickets
                client={client}
                dataForm={dataForm}
                results={results}
                loading={loading}
                isIframe={props.isIframe}
              />
            )}

            {ticketType === 'passes' && (
              <ResultsComponentPasses
                client={client}
                dataForm={dataForm}
                destination={destinationPasses}
                results={results}
                loading={loading}
                isIframe={props.isIframe}
              />
            )}
          </>
        )}
      </Container>
    </Grid>
  );
}

const mapStateToProps = reducers => {
  return reducers.trainsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    searchProducts: (token, dataForm, signalAbort = null) => dispatch(searchActionTrains(token, dataForm, signalAbort)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);
