import React, { Fragment, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Fab, Icon, Paper, Typography } from '@mui/material';
import RawMarkup from '../../common/RawMarkup';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function MessagesSidebar({ messages }) {
  const { t } = useTranslation();
  const [remarks, setRemarks] = useState('');

  const getMessages = (message, remarks = false) => {
    return message.items.filter(elem => {
      if (remarks) {
        return elem.type === 'remarks';
      } else {
        return elem.type !== 'remarks'
      }
    })
  }

  const parseHTML = (remarks) => {
    if (remarks) {
      const textArea = document.createElement("textarea");
      textArea.innerHTML = remarks.map(elem => elem.description).join('\n\n');
      return <div dangerouslySetInnerHTML={{ __html: textArea.value }} />
    }
  };

  const GetItemsList = ({ message }) => {
    return getMessages(message).map((item, i) => {
      const className = ['row', item.className, Boolean(item.icon) ? '' : 'content'].filter(elem => elem).join(' ');
      return <Typography key={i} variant="body2" className={className} >
        {message?.category === 'restrictions' ? (
          <Fragment>
            {item.title}
            {item.description && <RawMarkup content={item.description} containerType='span' />}
          </Fragment>
        ) : (
          <Fragment>
            {item.icon && <Icon className='message-icon'>{item.icon}</Icon>}
            {item.title}
            {item.description && <RawMarkup content={item.description} containerType='span' />}
          </Fragment>
        )}
      </Typography>
    });
  }

  return (
    <Fragment>
      {messages.map((message, i) => (
        <Paper className="paper" key={i}>
          <div className='message column'>
            {(!message.icon || message.category === 'restrictions') ? (
              <div>
                <GetItemsList message={message} />
              </div>
            ) : (
              <Fragment>
                <div>
                  <Icon>{message.icon}</Icon>
                </div>
                <div>
                  <GetItemsList message={message} />
                </div>
              </Fragment>
            )}
          </div>

          {getMessages(message, true).length > 0 && (
            <div className='remarks-container'>
              <Button color='primary' onClick={() => setRemarks(getMessages(message, true))}>Ver comentarios</Button>
            </div>
          )}
        </Paper>
      ))}

      <Dialog open={Boolean(remarks)} onClose={() => setRemarks(null)} className='remarks-modal'>
        <Fab size="small" onClick={() => setRemarks(null)} className='close-button'>
          <Close fontSize='small' />
        </Fab>

        <DialogTitle>
          {t('checkout.common.comments')}
        </DialogTitle>
        <DialogContent>
          {parseHTML(remarks)}
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
