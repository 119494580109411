import React from 'react'
import { Card, CardMedia,CardContent, Typography , Button, Link, Container, useTheme, useMediaQuery} from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function BannerItem({ item, isMobile }) {
  const { image_url, image_url_mobile, title, description, button_text, link } = item

  return (
    <Card>
      <CardMedia
      image={isMobile ? image_url_mobile : image_url}
      title={title}
      sx={{ paddingTop: '56.25%' }}  // relación de 16:9
      />
      <CardContent>
        {title && <Typography variant="h5" className='title-banner-promotions'>{title}</Typography>}
        {description && <Typography variant="subtitle1" sx={{mb: 2}}>{description}</Typography>}
        {button_text && link && (
          <Button
            variant="contained"
            component={Link}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {button_text}
          </Button>
        )}
      </CardContent>
    </Card>
  )
}

export default function BannerPromotions({ title, banners }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const carouselResponsive = {
    breakpoint3: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    breakpoint2: {
      breakpoint: { max: 1023, min: 768 },
      items: 2,
    },
    breakpoint1: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const sortBanners = () => {
    return banners?.sort((a, b) => (a.order || Infinity) - (b.order || Infinity)) || [];
  }

  if (banners && banners.length > 0) {
    return (
      <section className='banner-promotions-section'>
        <Container>
          <Typography variant="h4" component="h2" sx={{ mb: 2 }}>{title}</Typography>
        </Container>

        <Container className='container-slider'>
          <Carousel responsive={carouselResponsive}>
            {sortBanners().map((banner, index) => (
              <BannerItem key={index} item={banner} isMobile={isMobile} />
            ))}
          </Carousel>
        </Container>
      </section>
    )
  }
}
