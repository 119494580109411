import React, { useState } from 'react'
import {  Link, Container, Box, useTheme, useMediaQuery } from '@mui/material';
import DialogMessage from '../DialogMessage';
import RawMarkup from '../RawMarkup'

function BannerItem({ item, isMobile }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { image_url, image_url_mobile, title, link, html_content } = item;

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box className="banner-item banner-single-image" sx={{ mb: 4 }}>

      {link ? (
        <Link title={title} href={link}
          onClick={html_content ? handleOpenDialog : undefined}
          target={html_content ? undefined : "_blank"}
          rel={html_content ? undefined : "noopener noreferrer"}
        >
          <img src={isMobile ? image_url_mobile : image_url} alt={title} />
        </Link>
      ) : (
        <img src={isMobile ? image_url_mobile : image_url} alt={title}
          style={{ cursor: html_content ? 'pointer' : 'default' }}
          onClick={html_content ? handleOpenDialog : undefined}
          target={html_content ? undefined : "_blank"}
          rel={html_content ? undefined : "noopener noreferrer"}
        />
      )}

      {html_content &&
        <DialogMessage
          open={dialogOpen}
          onClose={handleCloseDialog}
          title={title}
          message={<RawMarkup content={html_content} />}
          showCloseButton
        />
      }
    </Box>
  )
}

export default function BannerSingleImage({ banners }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const sortBanners = () => {
    return banners?.sort((a, b) => (a.order || Infinity) - (b.order || Infinity)) || [];
  }

  if (banners && banners.length > 0) {
    return (
      <section className='banner-image-section'>
        <Container>
          {sortBanners().map((item, index) =>  <BannerItem key={index} item={item} isMobile={isMobile} /> )  }
        </Container>
      </section>
    )
  }
}
