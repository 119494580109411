
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function DialogTimer(props) {
    const { t } = useTranslation();

    return (
        <Dialog open className="dialog-timer">
            <DialogTitle className="text-center">
                {t('checkout.timer.expiredSearch')}
            </DialogTitle>
            <DialogContent className="text-center">
                {t('checkout.timer.returnResultsOf', { result: props.module?.moduleName?.toLowerCase() } )}
            </DialogContent>
            {(props.redirect || props.module?.path) && (
                <DialogActions className="align-center">
                    <Button href={props.redirect || props.module?.path} variant="contained">{t('checkout.timer.returnResults')}</Button>
                </DialogActions>
            )}
      </Dialog>
    );
}