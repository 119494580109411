import React, { useEffect, useState } from 'react';
import { Alert, Button, Grid, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material';
import InputWrapper from '../../common/InputWrapper';
import { getCountry } from '../../../store/services/InfoFormServices';

const ZohoFormRegister = () => {
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    window.$zoho = {};
    window.$zoho.salesiq = window.$zoho.salesiq
      || {
        widgetcode: '55d893b31b577635128f7a49bd1800f33ad41c180f2e687953a08fe8772078341a2010ab7b6727677d37b27582c0e9c4',
        values: {},
        ready: () => {}
      };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zsiqscript';
    script.defer = true;
    script.src = 'https://salesiq.zoho.com/widget';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'wf_anal';
    script.src = 'https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=cc8b4aa00508fd2123e12d0350ebd13fe8601b59da2180062064c03945928a0fgidd6b2ae3f885132478fb64cf6fde98f06ef836b27e0b9026a4a36b8f43b865efbgid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const reloadCaptcha = () => {
    const captcha = document.getElementById('imgid4961372000000397401');
    if (captcha.src.indexOf('&d') !== -1) {
      captcha.src = captcha.src.substring(0, captcha.src.indexOf('&d')) + '&d' + new Date().getTime();
    } else {
      captcha.src = captcha.src + '&d' + new Date().getTime();
    }
  }

  const trackVisitor = () => {
    try {
      if (window.$zoho?.salesiq) {
        const LDTuvidObj = document.forms['WebToLeads']['LDTuvid'];
        if (LDTuvidObj) {
          LDTuvidObj.value = window.$zoho.salesiq.visitor.uniqueid();
        }
        const name = `${document.forms['WebToLeads']['First Name']?.value} ${document.forms['WebToLeads']['Last Name']?.value}`;
        window.$zoho.salesiq.visitor.name(name);
        const email = document.forms['WebToLeads']['Email']?.value;
        window.$zoho.salesiq.visitor.email(email);
      }
    } catch (e) {}
  }

  const checkMandatory = () => {
    const mndFields = ['Company', 'First Name', 'Last Name', 'Email', 'Phone', 'LEADCF1', 'enterdigest'];
    const errorsAux = {};
    let firstError = null;

    mndFields.forEach(mndField => {
      const fieldObj = document.forms['WebToLeads'][mndField];
      const value = fieldObj?.value;
      if (!value || value.replace(/^\s+|\s+$/g, '').length === 0 || value === '-None-')  {
        errorsAux[mndField] = 'Introduce un valor';
        firstError = firstError || mndField;
      }
    });

    if (firstError !== null) {
      document.forms['WebToLeads'][firstError].focus();
      setOpenSnackbar(true);
    } else {
      trackVisitor();
    }

    setErrors(errorsAux);

    return !Boolean(firstError);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (checkMandatory()) {
      event.target.submit();
    }
  };

  return (
    <div className='zoho-form-register'>
      <div id='crmWebToEntityForm' className='crm-web-to-entity-form'>
        <form
          action='https://crm.zoho.com/crm/WebToLeadForm'
          name='WebToLeads'
          method='POST'
          onSubmit={handleSubmit}
          acceptCharset='UTF-8'
        >
          <Typography component='h2' variant='h2' className='zcwf-title'>Registra tu agencia:</Typography>
          <TextField
            name='xnQsjsdp'
            value='d6b2ae3f885132478fb64cf6fde98f06ef836b27e0b9026a4a36b8f43b865efb'
            style={{ display: 'none' }}
          />
          <TextField
            name='zc_gad'
            value=''
            style={{ display: 'none' }}
          />
          <TextField
            name='xmIwtLD'
            value='cc8b4aa00508fd2123e12d0350ebd13fe8601b59da2180062064c03945928a0f'
            style={{ display: 'none' }}
          />
          <TextField
            name='actionType'
            value='TGVhZHM='
            style={{ display: 'none' }}
          />
          <TextField
            name='returnURL'
            value={`${window.location.origin}/gracias`}
            style={{ display: 'none' }}
          />
          <TextField
            name='ldeskuid'
            value=''
            style={{ display: 'none' }}
          />
          <TextField
            name='LDTuvid'
            value=''
            style={{ display: 'none' }}
          />

          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12} className='wfrm-fld-dpNn'>
              <InputWrapper label='Estado de Posible cliente'>
                <Select size='small' name='Lead Status' defaultValue='No Contactado'>
                  <MenuItem value='-None-'>-None-</MenuItem>
                  <MenuItem value='No Contactado'>No Contactado</MenuItem>
                  <MenuItem value='Escalado Comercial'>Escalado Comercial</MenuItem>
                  <MenuItem value='Contactar a Futuro'>Contactar a Futuro</MenuItem>
                  <MenuItem value='Agencia Autorizada'>Agencia Autorizada</MenuItem>
                  <MenuItem value='Agencia Rechazad'>Agencia Rechazad</MenuItem>
                  <MenuItem value='Agencia Aprobada'>Agencia Aprobada</MenuItem>
                  <MenuItem value='Agencia Denegada'>Agencia Denegada</MenuItem>
                </Select>
              </InputWrapper>
            </Grid>

            <Grid item xs={12} className='wfrm-fld-dpNn'>
              <InputWrapper label='Medio de Contacto'>
                <Select size='small' name='LEADCF2' defaultValue='Formulario'>
                  <MenuItem value='-None-'>-None-</MenuItem>
                  <MenuItem value='Chat.'>Chat.</MenuItem>
                  <MenuItem value='Whatsapp'>Whatsapp</MenuItem>
                  <MenuItem value='Llamada'>Llamada</MenuItem>
                  <MenuItem value='Mensaje'>Mensaje</MenuItem>
                  <MenuItem value='Formulario'>Formulario</MenuItem>
                  <MenuItem value='Comentario'>Comentario</MenuItem>
                  <MenuItem value='Mail'>Mail</MenuItem>
                </Select>
              </InputWrapper>
            </Grid>

            <Grid item xs={12} className='wfrm-fld-dpNn'>
              <InputWrapper label='Fuente de Posible cliente' >
                <Select size='small' name='Lead Source' defaultValue='Sitio Web'>
                  <MenuItem value='-None-'>-None-</MenuItem>
                  <MenuItem value='>Recomendación'>Recomendación</MenuItem>
                  <MenuItem value='Facebook'>Facebook</MenuItem>
                  <MenuItem value='Google Ads'>Google Ads</MenuItem>
                  <MenuItem value='Relaciones Públicas'>Relaciones Públicas</MenuItem>
                  <MenuItem value='Seminario'>Seminario</MenuItem>
                  <MenuItem value='Sitio Web'>Sitio Web</MenuItem>
                  <MenuItem value='Instagram'>Instagram</MenuItem>
                  <MenuItem value='Ladevi'>Ladevi</MenuItem>
                </Select>
              </InputWrapper>
            </Grid>

            <Grid item xs={12}>
              <InputWrapper
                label='Nombre de Agencia'
                required
                error
                helperText={errors['Company'] || ''}
              >
                <TextField size='small' name='Company' />
              </InputWrapper>
            </Grid>

            <Grid item xs={6}>
              <InputWrapper
                label='Nombre'
                required
                error
                helperText={errors['First Name'] || ''}
              >
                <TextField size='small' name='First Name' />
              </InputWrapper>
            </Grid>

            <Grid item xs={6}>
              <InputWrapper
                label='Apellido'
                required
                error
                helperText={errors['Last Name'] || ''}
              >
                <TextField size='small' name='Last Name' />
              </InputWrapper>
            </Grid>

            <Grid item xs={12}>
              <InputWrapper
                label='Correo electrónico'
                required
                error
                helperText={errors['Email'] || ''}
              >
                <TextField size='small' type='email' name='Email' />
              </InputWrapper>
            </Grid>

            <Grid item xs={4}>
              <InputWrapper
                label='Teléfono'
                required
                error
                helperText={errors['Phone'] || ''}
              >
                <TextField size='small' name='Phone' />
              </InputWrapper>
            </Grid>

            <Grid item xs={4}>
              <InputWrapper
                label='País'
                required
                error
                helperText={errors['LEADCF1'] || ''}
              >
                <Select size='small' name='LEADCF1' defaultValue='-None-'>
                  <MenuItem value='-None-'>-None-</MenuItem>
                  {getCountry().map((elem, i) => (
                    <MenuItem value={elem.description} key={i}>
                      {elem.description}
                    </MenuItem>
                  ))}
                </Select>
              </InputWrapper>
            </Grid>

            <Grid item xs={4}>
              <InputWrapper
                label='Sitio web'
              >
                <TextField size='small' name='Website' />
              </InputWrapper>
            </Grid>

            <Grid item xs={4}>
              <InputWrapper
                label='Introduzca el captcha'
                required
                error
                helperText={errors['enterdigest'] || ''}
              >
                <TextField size='small' name='enterdigest' />
              </InputWrapper>
            </Grid>

            <Grid item xs={4}>
              <img
                id='imgid4961372000000397401'
                src='https://crm.zoho.com/crm/CaptchaServlet?formId=cc8b4aa00508fd2123e12d0350ebd13fe8601b59da2180062064c03945928a0f&grpid=d6b2ae3f885132478fb64cf6fde98f06ef836b27e0b9026a4a36b8f43b865efb'
                alt='Captcha'
              />
              <Button onClick={reloadCaptcha} className='captcha-button'>
                Recargar
              </Button>
            </Grid>

            <Grid item xs={4} />

            <Grid item>
              <Button variant='contained' color='secondary' type='submit'>
                Enviar
              </Button>
            </Grid>

            <Grid item>
              <Button variant='contained' color='secondary' type='reset'>
                Reestablecer
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenSnackbar(null)} severity="error" sx={{ width: '100%' }}>
          El formulario no es válido, por favor corregir posibles errores
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ZohoFormRegister;
